import React, { Component } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../common/CustomButton";
import icon01 from "../../assets/images/icon-01.png";
import icon02 from "../../assets/images/icon-02.png";
import icon03 from "../../assets/images/icon-03.png";
import icon04 from "../../assets/images/icon-04.png";
import { URL } from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";
import FileViewer from "react-file-viewer";
import ViewLMSFile from "../SchoolLMS/ViewLMSFile";
import EMCQ from "./EGames/EMCQ";
import EFlashCards from "./EGames/EFlashCards";
import { axiosPost } from "../../utils/AxiosApi";
import CourseClassResources from "../../pages/CoursePlan/CourseClassResource";

import defaultSubjectImage from "../../assets/images/defaultSubject.svg";

import iconSign from "../../assets/images/sign_language.png";
import EMemoryGame from "./EGames/EMemoryGame";
import { displayErrorAlert } from "../../utils/Utils";
import FileModal from "../ViewFile/FileModal";

class LibraryContent extends Component {
  state = {
    fileViewerModal: false,
    filePath: "",
    fileType: "",
    activeLMSFile: null,
  };

  handleFileViewer = (item) => {
    this.setState({ activeLMSFile: item }, function () {
      this.toggleFileViewer();
    });

    // this.setState({
    //   filePath: item.filePath,
    //   fileType: item.fileType,
    // });
  };

  toggleFileViewer = () => {
    this.setState({
      fileViewerModal: !this.state.fileViewerModal,
    });
  };

  // View subject resource modal
  viewSubjectResourceModal = (id) => {
    const data = {
      coursePlanId: id,
    };
    axiosPost(URL.getCoursePlanResourcesCore, data, (response) => {
      if (response.data.status === 200) {
        this.setState(
          {
            selectedSubjectResources: response.data.data,
          },
          () => {
            this.toggleFileModal();
          }
        );
      }
    });
  };

  getAllResources = () => {
    let data = {
      coursePlanId: this.props.courseList[0].id,
    };
    axiosPost(
      URL.getCoursePlanResources,
      data,
      (res) => {
        if (res.status === 200) {
          this.setState({ selectedSubjectResources: res.data.data }, () => {
            this.toggleFileModal();
          });
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  toggleSyllabusResourcesModalView = (param) => {
    let data = {
      coursePlanSyllabusId: param.id,
      coursePlanId: param.coursePlanId,
    };
    axiosPost(URL.getCoursePlanResourcesCore, data, (res) => {
      if (res.status === 200) {
        this.setState({ selectedSubjectResources: res.data.data }, () => {
          this.toggleFileModal();
        });
      }
    });
  };
  toggleOtherCatagoriesResourcesModalView = (item) => {
    debugger
    this.setState({ selectedSubjectResources: item }, () => {
      this.toggleFileModal();
    });
  };

  toggleFileModal = () => {
    this.setState({
      fileModal: !this.state.fileModal,
    });
  };

  renderSubTopic = (item) => {
    return (
      <>
        {item.subTopic.map((items, cIdx) => (
          <>
            <div className="coursePlanContent d-flex">
              <div>
                <h4 className="heading-06">
                  {cIdx + 1}. {items?.name}
                </h4>

                {items.description && items.description !== "" ? (
                  <li className="heading-06">{items.description}</li>
                ) : null}
              </div>
              {items.hasResource ? (
                <CustomButton
                  text="View Resource"
                  customStyle="resourceBtn"
                  style={{
                    marginRight: "unset",
                    marginTop: "unset",
                  }}
                  onClick={() => this.toggleSyllabusResourcesModalView(items)}
                />
              ) : null}
            </div>
            {items.subTopic && this.renderSubTopic(items)}
          </>
        ))}
      </>
    );
  };

  hasOtherCategoriesCount = () => {
    this.props.selectedSubject.otherActivitiesCategories.map((items, key) => {
      items.activitiesCategoriesResources.map(item => {
        if (item?.subjectId == this.props?.selectedSubject?.coreSubjectId && (!item?.classId ||
          item?.classId == this.props?.grade?.coreYearLevelId ||
          (!this.props?.grade?.coreYearLevelId && item?.classId == this.props?.grade?.id)))
          this.props.updateCategoryCount();
      })
    })
  }
  componentDidMount() {
    this.hasOtherCategoriesCount();
  }

  getCoursePlanbyClassData = () => { };
  render() {
    let { resources, lmsToggled, courseList } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-9 mb-5">
            <div className="row">
              <div className="col">
                <CustomButton
                  text="Go Back"
                  onClick={this.props.goBack}
                  customStyle="resourceBtn"
                  style={{ marginLeft: "unset" }}
                ></CustomButton>
              </div>
              <div className="col text-right">
                {this.props.coursePlanSelected &&
                  courseList &&
                  courseList[0]?.coursePlanSyllabus?.length ? (
                  <div className="col text-end">
                    <CustomButton
                      text="View Resources"
                      customStyle="resourceBtn"
                      style={{ marginRight: "unset" }}
                      onClick={this.getAllResources}
                    ></CustomButton>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col text-center">
                <h2 className="heading-06 text-center">
                  {this.props.selectedSubject?.name}
                </h2>
              </div>
            </div>
            {this.props.coursePlanSelected ? (
              <div className="row mt-2 mb-2">
                {courseList?.length > 0 ? (
                  courseList.map((items) => {
                    return (
                      <>
                        {items?.coursePlanSyllabus?.map((item) => {
                          return (
                            <>
                              <div className="col-md-12">
                                <div
                                  className="description-shadow"
                                  style={{ margin: "unset", marginTop: "50px" }}
                                >
                                  <div className="listBox coursePlanCss">
                                    <h2
                                      className="listTitle"
                                      style={{ fontSize: "1.5rem" }}
                                    >
                                      {item?.name}
                                    </h2>
                                    <div>
                                      {item.description &&
                                        item.description !== "" ? (
                                        <li className="heading-06">
                                          {item.description}
                                        </li>
                                      ) : null}
                                    </div>
                                    {item.hasResource ? (
                                      <CustomButton
                                        text="View Resource"
                                        customStyle="resourceBtn"
                                        style={{
                                          marginRight: "unset",
                                          marginTop: "unset",
                                        }}
                                        onClick={() =>
                                          this.toggleSyllabusResourcesModalView(
                                            item
                                          )
                                        }
                                      />
                                    ) : null}
                                  </div>

                                  {item.subTopic && item.subTopic.length > 0
                                    ? this.renderSubTopic(item)
                                    : ""}

                                  {/* {item?.subTopic?.map((items, cIdx) => {
                                    return (
                                      <>
                                        <div className="coursePlanContent">
                                          <h4 className="heading-06">
                                            {cIdx + 1}. {items?.name}
                                          </h4>
                                          {items.description &&
                                          items.description !== "" ? (
                                            <li className="heading-06">
                                              {items.description}
                                            </li>
                                          ) : null}
                                        </div>
                                        {items?.subTopic?.map((values) => {
                                          return (
                                            <div className="coursePlanSubtopic">
                                              <h6 className="heading-06">
                                                - {values?.name}
                                              </h6>
                                              {values.description &&
                                              values.description !== "" ? (
                                                <li className="heading-06">
                                                  {values.description}
                                                </li>
                                              ) : null}
                                            </div>
                                          );
                                        })}
                                      </>
                                    );
                                  })} */}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  })
                ) : (
                  <div className="col-md-12 text-center">
                    <i>No syllabus added.</i>
                  </div>
                )}
              </div>
            ) : resources && lmsToggled ? (
              <div className="row mt-2 mb-2">
                {resources.length > 0 ? (
                  resources?.map((r, idx) => {
                    return (
                      <div className="col-md-12" key={idx}>
                        <div
                          className="description-shadow"
                          style={{ margin: "unset", marginTop: "50px" }}
                        >
                          <div className="listBox coursePlanCss">
                            <h2
                              className="listTitle"
                              style={{ fontSize: "1.5rem" }}
                            >
                              {r?.title}
                            </h2>
                            {r.description && r.description !== "" ? (
                              <li className="heading-06">{r.description}</li>
                            ) : null}
                          </div>
                          {r?.schoolLmsResources.length > 0 ? (
                            <CustomButton
                              text="View Resource"
                              customStyle="resourceBtn"
                              style={{ marginRight: "unset" }}
                              onClick={() => this.handleFileViewer(r)}
                            />
                          ) : null}
                          {/* {r?.schoolLmsResources?.map((items, cIdx) => {
                            return (
                              <>
                                <div className="coursePlanContent">
                                  <h4 className="heading-06">
                                    <CustomButton
                                      text="View Resource"
                                      customStyle="resourceBtn"
                                      style={{ marginRight: "unset" }}
                                      onClick={() =>
                                        this.handleFileViewer(items)
                                      }
                                    ></CustomButton>
                                  </h4>
                                </div>
                              </>
                            );
                          })} */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-md-12 text-center">
                    <strong>No Resources added</strong>
                  </div>
                )}
              </div>
            ) : this.props.gamesToggle ? (
              this.props.mcqBox ? (
                <EMCQ
                  selectedSubject={this.props.selectedSubject}
                  grade={this.props.grade}
                />
              ) : this.props.flashCardBox ? (
                <EFlashCards
                  selectedSubject={this.props.selectedSubject}
                  grade={this.props.grade}
                />
              ) : this.props.memoryGameBox ? (
                <EMemoryGame />
              ) : (
                <div className="row mt-2">
                  <div className="col">
                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                      <div
                        className="grade-shadow"
                        onClick={() => this.props.toggleMCQ()}
                      >
                        <div className="grade-icon-image">
                          <img src={icon01} alt="icon-01" />
                        </div>
                        <span className="heading-06">MCQ</span>
                      </div>
                      <div
                        className="grade-shadow"
                        onClick={() => this.props.toggleFlashCards()}
                      >
                        <div className="grade-icon-image">
                          <img src={icon01} alt="icon-01" />
                        </div>
                        <span className="heading-06">Flash Cards</span>
                      </div>
                      <div
                        className="grade-shadow"
                        onClick={() => this.props.toggleMemoryGame()}
                      >
                        <div className="grade-icon-image">
                          <img src={icon01} alt="icon-01" />
                        </div>
                        <span className="heading-06">Memory Game</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : this.props.readingMaterials ? (
              <div className="row mt-2">
                <div className="col">
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <div
                      className="grade-shadow"
                      onClick={() => this.props.toggleLMS("textbooks")}
                    >
                      <div className="grade-icon-image">
                        <img src={icon02} alt="icon-01" />
                      </div>
                      <span className="heading-06">Text Books</span>
                    </div>
                    <div
                      className="grade-shadow"
                      onClick={() => this.props.toggleLMS("question-bank")}
                    >
                      <div className="grade-icon-image">
                        <img src={icon03} alt="icon-01" />
                      </div>
                      <span className="heading-06">Question Bank</span>
                    </div>
                    <div
                      className="grade-shadow"
                      onClick={this.props.toggleFeatureSelect}
                    >
                      <div className="grade-icon-image">
                        <img src={icon01} alt="icon-01" />
                      </div>
                      <span className="heading-06">Syllabus</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : this.props.otherCategories ?
              <>
                {this.props?.selectedOtherCategories?.activitiesCategoriesResources.length > 0 ? (this.props?.selectedOtherCategories?.activitiesCategoriesResources?.map((item, key) => {
                  if (item?.subjectId == this.props.selectedSubject.coreSubjectId && (!item?.classId ||
                    item?.classId == this.props?.grade?.coreYearLevelId ||
                    (!this.props?.grade?.coreYearLevelId && item?.classId == this.props.grade.id)))
                    return (
                      <>
                        <div className="col-md-12">
                          <div
                            className="description-shadow"
                            style={{ margin: "unset", marginTop: "50px" }}
                          >
                            <div className="listBox coursePlanCss">
                              <h2
                                className="listTitle"
                                style={{ fontSize: "1.5rem" }}
                              >
                                {item?.title}
                              </h2>
                              <div>
                                {item.description &&
                                  item.description !== "" ? (
                                  <li className="heading-06">
                                    {item.description}
                                  </li>
                                ) : null}
                              </div>
                              {/* {item.hasResource ? ( */}
                              <CustomButton
                                text="View Resource"
                                customStyle="resourceBtn"
                                style={{
                                  marginRight: "unset",
                                  marginTop: "unset",
                                }}
                                onClick={() =>
                                  this.toggleOtherCatagoriesResourcesModalView(
                                    [item]
                                  )
                                }
                              />
                              {/* ) : null} */}
                            </div>

                            {item.subTopic && item.subTopic.length > 0
                              ? this.renderSubTopic(item)
                              : ""}
                          </div>
                        </div>
                      </>
                    )
                })) : <div className="col-md-12 text-center">
                  <strong>No Resources added</strong>
                </div>}
              </>
              // <></>
              : (
                <div className="row mt-2">
                  <div className="col">
                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                      {/* <div
                      className="grade-shadow"
                      onClick={() => this.props.toggleLMS("curriculum")}
                    >
                      <div className="grade-icon-image">
                        <img src={icon01} alt="icon-01" />
                      </div>
                      <span className="heading-06">Curriculum</span>
                    </div> */}
                      <div
                        className="grade-shadow"
                        onClick={() => this.props.toggleLMS("curriculum")}
                      >
                        <div className="grade-icon-image">
                          <img src={icon04} alt="icon-01" />
                        </div>
                        <span className="heading-06">School Curricula</span>
                      </div>

                      <div
                        className="grade-shadow"
                        onClick={() => this.props.toggleReadingMaterials()}
                      >
                        <div className="grade-icon-image">
                          <img src={icon02} alt="icon-01" />
                        </div>
                        <span className="heading-06">Reading Materials</span>
                      </div>

                      <div
                        className="grade-shadow"
                        onClick={() => this.props.toggleGames()}
                      >
                        <div className="grade-icon-image">
                          <img src={icon03} alt="icon-01" />
                        </div>
                        <span className="heading-06">Games</span>
                      </div>

                      {/* <div
                      className="grade-shadow"
                      onClick={() => this.props.toggleLMS("textbooks")}
                    >
                      <div className="grade-icon-image">
                        <img src={icon02} alt="icon-01" />
                      </div>
                      <span className="heading-06">Text Books</span>
                    </div>
                    <div
                      className="grade-shadow"
                      onClick={() => this.props.toggleLMS("question-bank")}
                    >
                      <div className="grade-icon-image">
                        <img src={icon03} alt="icon-01" />
                      </div>
                      <span className="heading-06">Question Bank</span>
                    </div> */}
                      <div
                        className="grade-shadow"
                        onClick={() => this.props.toggleLMS("av-resource")}
                      >
                        <div className="grade-icon-image">
                          <img src={icon04} alt="icon-01" />
                        </div>
                        <span className="heading-06">A/V Materials</span>
                      </div>

                      <div
                        className="grade-shadow"
                        onClick={() => this.props.toggleLMS("sign-language")}
                      >
                        <div className="grade-icon-image">
                          <img src={iconSign} alt="icon-01" />
                        </div>
                        <span className="heading-06">
                          Sign Language Resources
                        </span>
                      </div>
                      {this.props.otherCategoriescount ?
                        (this.props?.selectedSubject?.otherActivitiesCategories?.map((item, index) => {
                          const { thumbnailPath, name } = item;
                          return (<div
                            className="grade-shadow"
                            onClick={() => this.props.toggleOtherCategories(item)}
                          >
                            <div className="grade-icon-image">
                              {thumbnailPath && thumbnailPath !== "" ? (
                                <div className="grade-icon-image">
                                  <img
                                    src={URL.imageSelectURL + thumbnailPath}
                                    onError={(e) =>
                                      (e.target.src = defaultSubjectImage)
                                    }
                                  />
                                </div>
                              ) : null}
                            </div>
                            <span className="heading-06">
                              {name}
                            </span>
                          </div>)
                        })) : <></>
                      }
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div
            className="col-md-3 subjectMobileMenu"
            style={{ borderLeft: "2px solid #4878AE" }}
          >
            {this.props.subjectList.length > 0
              ? this.props.subjectList
                .filter((el) => el.name !== this.props.selectedSubject.name)
                .map((sub, idx) => {
                  return (
                    <div
                      className={
                        "d-flex align-items-center justify-content-between heading-06 drop-down mb-3 " +
                        (idx === 0 ? "mt-5" : "")
                      }
                      onClick={() => this.props.selectSubject(sub)}
                      style={{ cursor: "pointer" }}
                    >
                      <span>{sub?.name}</span>
                    </div>
                  );
                })
              : null}
          </div>
        </div>

        <ModalWindow
          modal={this.state.fileModal}
          toggleModal={this.toggleFileModal}
          modalHeader="Resource"
          size="xl"
          modalBody={
            <CourseClassResources
              selectedResources={this.state.selectedSubjectResources}
            ></CourseClassResources>

            // <FileModal
            //   isNotice={false}
            //   isSyllabys={true}

            //   handleChangeFile={this.handleChangeFile}
            //   toggleModal={this.toggleFileModal}
            //   fileExist={true}
            //   activeIndex={this.state.activeIndex}
            //   fileType={this.state.fileType}
            //   filePath={this.state.filePath}
            //   files={this.state.files}
            //   images={this.state.images}
            //   links={this.state.links}
            //   type={this.state.type}
            // ></FileModal>
          }
        ></ModalWindow>
        <ModalWindow
          modal={this.state.fileViewerModal}
          modalClass="fullModal"
          toggleModal={this.toggleFileViewer}
          size="lg"
          modalHeader="Resource Files"
          modalBody={<ViewLMSFile activeLMSFile={this.state.activeLMSFile} />}
        />
      </div>
    );
  }
}

export default LibraryContent;
