import React, { useEffect, useState } from "react";
import CustomButton from "../../components/common/CustomButton";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import NavBar from "../../components/FrontPage/NavBar";
import JitsiMeet from "../../components/VideoConferencing/jitsiMeet";
import ModalWindow from "../../UI/ModalWindow";
import { axiosPost } from "../../utils/AxiosApi";
import { postType, URL } from "../../utils/Constants";
import { displayErrorMessage, displayMessage } from "../../utils/Utils";
import NoticeFileViewer from "./NoticeFileViewer";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import swal from "sweetalert";

const AllNotices = (props) => {
  const [activeNoticeFile, setActiveNoticeFile] = useState([]);
  const [resourceModal, setResourceModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [noticeList, setNoticeList] = useState([]);
  const [logoPreview, setLogoPreview] = useState(null);
  const [disclaimer, setDisclaimer] = useState("");
  const [activeNotice, setActiveNotice] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [userName, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [jitsiModel, setJitsiModel] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [videoConferenceId, setVideoConferenceId] = useState(null);

  const handleViewResources = (notice) => {
    setActiveNotice(notice);
    setActiveNoticeFile(notice.contents);
    setResourceModal(true);
  };

  const toggleFileViewer = () => {
    setResourceModal(!resourceModal);
  };

  useEffect(() => {
    getAllNoticeList();
    getWebsiteConfig();
  }, []);

  const getAllNoticeList = () => {
    axiosPost(URL.getNoticeCore, {}, (response) => {
      if (response.status === 200) {
        // setNoticeList(response.data.data);
        const notices = response.data.data;
        let allPromises = [];
        notices.forEach(async (notice, idx) => {
          if (notice.postType === postType.videoConference) {
            let param = {
              postId: notice.id,
              profilePeopleId: localStorage.getItem("peopleId"),
            };
            let selectVideoConferencePostPromise = new Promise(
              (resolve, reject) => {
                axiosPost(URL.videoConferenceSelectByPostId, param, (res) => {
                  if (res.status === 200) {
                    resolve(res.data.data);
                  }
                });
              }
            );
            allPromises.push(selectVideoConferencePostPromise);
            let replaceData = await selectVideoConferencePostPromise;
            let startDateTime = replaceData?.startDateTime
              ? moment(replaceData?.startDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;
            let endDateTime = replaceData?.endDateTime
              ? moment(replaceData?.endDateTime).format(
                  "Do MMMM, YYYY [at] hh:mm A"
                )
              : null;

            notice.heading = notice.heading
              ? notice.heading.replace(
                  "[sessionName]",
                  replaceData?.sessionName
                )
              : null;
            notice.textContent = notice.textContent.replace(
              "[peopleName]",
              replaceData?.peopleName
            );
            notice.textContent = notice.textContent.replace(
              "[message]",
              replaceData?.message
            );
            notice.textContent = notice.textContent.replace(
              "[sessionName]",
              replaceData?.sessionName
            );
            notice.textContent = notice.textContent.replace(
              "[startDateTime]",
              startDateTime
            );
            notice.textContent = notice.textContent.replace(
              "[endDateTime]",
              endDateTime
            );
            notice.textContent = notice.textContent.replace(
              "[hostName]",
              replaceData?.hostName
            );
          }
        });
        Promise.all(allPromises).then((data) => {
          let nl = notices.filter(
            (n) => n.postType !== postType.videoConference
          );
          setNoticeList(nl);
        });
      }
    });
  };

  const handleSearchNotice = (searchText) => {
    if (searchText !== "") {
      let data = {
        searchParam: searchText,
        central: true,
      };
      setNoticeList([]);
      axiosPost(URL.searchNoticeCore, data, (response) => {
        if (response.status === 200) {
          const notices = response.data.data;
          // setNoticeList(notices);
          let allPromises = [];
          notices.forEach(async (notice, idx) => {
            if (notice.postType === postType.videoConference) {
              let param = {
                postId: notice.id,
                profilePeopleId: localStorage.getItem("peopleId"),
              };
              let selectVideoConferencePostPromise = new Promise(
                (resolve, reject) => {
                  axiosPost(URL.videoConferenceSelectByPostId, param, (res) => {
                    if (res.status === 200) {
                      resolve(res.data.data);
                    }
                  });
                }
              );
              allPromises.push(selectVideoConferencePostPromise);
              let replaceData = await selectVideoConferencePostPromise;
              let startDateTime = replaceData?.startDateTime
                ? moment(replaceData?.startDateTime).format(
                    "Do MMMM, YYYY [at] hh:mm A"
                  )
                : null;
              let endDateTime = replaceData?.endDateTime
                ? moment(replaceData?.endDateTime).format(
                    "Do MMMM, YYYY [at] hh:mm A"
                  )
                : null;

              notice.heading = notice.heading
                ? notice.heading.replace(
                    "[sessionName]",
                    replaceData?.sessionName
                  )
                : null;
              notice.textContent = notice.textContent.replace(
                "[peopleName]",
                replaceData?.peopleName
              );
              notice.textContent = notice.textContent.replace(
                "[message]",
                replaceData?.message
              );
              notice.textContent = notice.textContent.replace(
                "[sessionName]",
                replaceData?.sessionName
              );
              notice.textContent = notice.textContent.replace(
                "[startDateTime]",
                startDateTime
              );
              notice.textContent = notice.textContent.replace(
                "[endDateTime]",
                endDateTime
              );
              notice.textContent = notice.textContent.replace(
                "[hostName]",
                replaceData?.hostName
              );
            }
          });
          Promise.all(allPromises).then((data) => {
            setNoticeList(notices);
          });
        }
      });
    } else {
      swal("Error", "Please enter search text");
    }
  };

  const getWebsiteConfig = () => {
    axiosPost(URL.getWebsiteConfig, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        if (data.length > 0) {
          // setWebTitle(data[0].title);
          setDisclaimer(data[0].disclaimer);
          // setFaviconPreview(data[0].faviconPath);
          setLogoPreview(data[0].logoPath);
        }
      }
    });
  };

  const toggleJitsiModal = () => {
    setJitsiModel(!jitsiModel);
  };

  const handleJoinConference = (id) => {
    let param = {
      postId: id,
      joinRequest: true,
    };
    axiosPost(
      URL.videoConferenceSelectByPostId,
      param,
      (response) => {
        if (response.status === 200 && response.data != null) {
          const roomId = response.data.data.jitsiRoomId;
          const userName = response.data.data.peopleName;
          const password = response.data.data.password;
          const createdByPeopleId = response.data.data.createdByPeopleId;
          const hasStarted = response.data.data.hasStarted;
          const isHost =
            localStorage.getItem("peopleId") == createdByPeopleId
              ? true
              : false;
          if (isHost && !hasStarted) {
            //update video conference
            let updateParam = response.data.data;
            updateParam.hasStarted = true;
            axiosPost(URL.videoConferenceUpdate, updateParam, (response) => {});
          }
          if (isHost || hasStarted) {
            setRoomId(roomId);
            setUsername(userName);
            setPassword(password);
            setIsHost(isHost);
            setVideoConferenceId(id);
            password !== "" && password !== null
              ? toggleJitsiModal()
              : alert("Password is Required to join the room");
          } else {
            displayMessage(
              "Contact Host Provider",
              "Video Conference has not been started by the host"
            );
          }
        }
      },
      (err) => {
        displayErrorMessage(err);
      }
    );
  };

  return (
    <>
      <NavBar
        homePage={props.location?.state?.noNav ? false : true}
        disclaimer={disclaimer}
        logoPreview={logoPreview}
        noNav={props.location?.state?.noNav}
        teacherPage={props.location?.state?.teacherPage}
        classPage={props.location?.state?.classPage}
        pathName={props.location?.pathname}
      />

      <div className="mt-3 p-5">
        <div className="whiteContainer">
          <CustomPageHeader title="Notices" />

          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-5">
              <input
                type="text"
                className="form-control"
                placeholder="Enter search text"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
              />
            </div>
            <div className="col-md-3">
              <CustomButton
                text="Search"
                customStyle="cusBtnFontSize"
                onClick={() => handleSearchNotice(searchText)}
              ></CustomButton>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table cusTable mt-3">
                <thead>
                  <tr>
                    <th>Heading </th>
                    <th>Description</th>
                    <th>Action</th>
                    {/* <th>Resources</th> */}
                  </tr>
                </thead>
                <tbody>
                  {noticeList.length > 0 ? (
                    noticeList
                      // .filter((nl) => nl.postType !== postType.videoConference)
                      .map((notice, index) => {
                        return (
                          <tr>
                            <td>{notice.heading}</td>
                            <td>{ReactHtmlParser(notice.textContent)}</td>
                            <td>
                              {notice.postType === postType.videoConference ? (
                                <CustomButton
                                  text="Join"
                                  customStyle="cusBtnFontSize"
                                  onClick={() =>
                                    handleJoinConference(notice.id)
                                  }
                                ></CustomButton>
                              ) : null}

                              <CustomButton
                                text={
                                  notice.postType === postType.videoConference
                                    ? "View Details"
                                    : "View Resources"
                                }
                                customStyle="cusBtnFontSize"
                                onClick={() => handleViewResources(notice)}
                              ></CustomButton>
                            </td>
                            {/* <td>
                            <CustomButton
                              text={notice.postType === "PT_VCON" ? "View Details" :"View Resources"}
                              customStyle="cusBtnFontSize"
                              onClick={() => handleViewResources(notice)}
                            ></CustomButton>
                          </td> */}
                            {/* <td>{notice.contactNumber}</td> */}
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={3}>No records to display!!</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ModalWindow
          modal={resourceModal}
          modalClass="fullModal"
          toggleModal={toggleFileViewer}
          size="lg"
          modalHeader={activeNotice?.heading}
          modalBody={
            <>
              <p className="py-3">
                {ReactHtmlParser(activeNotice?.textContent)}
              </p>
              {activeNoticeFile.length > 0 && (
                <NoticeFileViewer activeNoticeFile={activeNoticeFile} />
              )}
            </>
          }
        />
        {roomId && userName && password && (
          <ModalWindow
            modal={jitsiModel}
            toggleModal={toggleJitsiModal}
            modalHeader="Jitsi Video Conferencing for Imagine Inquiry Sys"
            size="xl"
            modalBody={
              <JitsiMeet
                isHost={isHost}
                getMeetingId={roomId}
                getDispNme={userName}
                toggleModal={toggleJitsiModal}
                getPassword={password}
                videoConferenceId={videoConferenceId}
              ></JitsiMeet>
            }
          ></ModalWindow>
        )}
      </div>
    </>
  );
};

export default AllNotices;
