/**
 * @createdBy   @author Sandeep Shakya
 * @description This component is used to display the notices published by the people.
 *              It has filters that helps to get the notices of certain dates only
 * @createdAt   2020-01-28
 */

import React, { Component } from "react";
import "../../../assets/scss/noticeDisplay.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../../assets/scss/coverPicture.scss";
import "../../../assets/scss/ResponsiveScss/responsiveNavbarPage.scss";
import { Spinner } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import NoticeModalBody from "./NoticeModalBody";
import Posts from "../PostSection/Posts";
import ModalWindow from "../../UI/ModalWindow";
import { displayErrorAlert, displayErrorMessage } from "../../../utils/Utils";
import {
  postType,
  URL,
  checkRolePermissions,
  userRoleCode,
  draggable,
  userRole,
} from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import ReactTooltip from "react-tooltip";
import {
  getNotices,
  getGroupNotices,
  getFamilyNotices,
  getNoticeSpinner,
} from "../../../ducks/PostsDucks";
import { connect } from "react-redux";
import swal from "sweetalert";
import _ from "lodash";
import { displayMessage } from "../../../utils/Utils";
import McqTestTemplate from "../../MCQ/ListMCQ/McqTestTemplate";
import McqStudentList from "../../MCQ/ListMCQ/McqStudentList";
import McqResultTemplate from "../../MCQ/ListMCQ/McqResultTemplate";
import $ from "jquery";
import CourseClassResource from "../../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseClassResource";
import CourseClassResources from "../../LearningManagementSystem/SubjectSetting/CourseClass/CourseClassResources/CourseResourceTemplate";
import SubmissionDetails from "../../Assignment/SubmissionDetails";
import JitsiMeet from "../../VideoConferencing/jitsiMeet";
import {
  youtubeLink,
  dailyMotionLink,
  vimeoLink,
  slideshareLink,
  defaultLinkURL,
} from "../../../utils/VideoURL";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import Chip from "@material-ui/core/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faFileCirclePlus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";

//To get different date options

const yesterday = moment().subtract(1, "days").toDate();
const todayDate = moment().toDate();
const dayBeforeYesterday = moment().subtract(2, "days").toDate();
const startOfWeek = moment().startOf("week").toDate();
const endOfWeek = moment().endOf("week").toDate();

const startOfLastWeek = moment().subtract(1, "weeks").startOf("week").toDate();

const endOfLastWeek = moment().subtract(1, "weeks").endOf("week").toDate();

const startOfLastMonth = moment()
  .subtract(1, "months")
  .startOf("month")
  .toDate();

const endOfLastMonth = moment().subtract(1, "months").endOf("month").toDate();

class NoticeDisplay extends Component {
  state = {
    modal: false,
    noticeModal: false,
    notice: {},
    searchText: "",
    notices: [],
    noticeDate: new Date(),
    totalNoofNotice: 0,
    noOfnotice: 1,
    // initialSlide: 0,
    searchByDate: "",
    fromDate: "",
    toDate: "",
    commentValue: "",
    comments: [],
    newCommentValue: "",
    minimize: false,
    keywordModal: false,
    targetGroups: [],
    noticeSpinner: false,
    memberOfFamily: null,
    groupName: null,
    familyId: null,
    peopleId: null,
    key: 0,
    mcqTest: [],
    minute: 15,
    seconds: 0,
    isSubmitted: false,
    mcqModal: false,
    mcqStudentListModal: false,
    mcqStudentList: [],
    mcqResult: "",
    correctAnswer: 0,
    incorrect: 0,
    unanswered: 0,
    totalAnswered: 0,
    displayNoticeSection: true,
    counter: 60000,
    // counter: 100,
    assignmentLink: 0,
    assignmentResource: 0,
    isAssignment: false,
    assignmentInfo: "",
    submissionModal: false,
    assignmentId: null,
    contents: [],
    links: [],
    submittedResourceModal: false,
    submittedResources: [],
    profilePeopleId: localStorage.getItem("profilePeopleId"),
    assignmentDropboxModal: false,
    assignmentStudent: [],
    jitsiModel: false,
    roomId: null,
    userName: null,
    password: null,
    isHost: null,
    videoConferenceId: null,
    currentStudentStatus: "",
    mark: "",
    point: "",
    status: "",
    remarks: "",
    fullMarks: 0,
    obtainedMarks: 0,
  };

  // checkSubmissionDate = (date) => {
  //   var d1 = new Date();
  //   var d2 = new Date(date);

  //   var canSubmit = d1.getTime() < d2.getTime();

  //   return canSubmit;
  // };

  clearAndUpdateInterval = () => {
    clearInterval(this.callNoticeInterval);
    this.setState({ counter: this.state.counter * 2 }, () => {
      this.callNoticeInterval = setInterval(
        this.callNoticeFunctions,
        this.state.counter
      );
    });
  };

  groupNoticeConfig = () => {
    let initialData = this.props.groupName;
    let groupName = initialData.replace(/_/g, " ");
    let param = {
      groupName: groupName,
      postType: postType.noticeCode,
      createdAt: this.state.noticeDate,
    };
    this.props.getGroupNotices(param);
    this.setState({ groupName: groupName });
  };

  familyNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: this.state.noticeDate,
    };
    let familyId = this.props.familyId;
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      param.familyId = familyId;
      this.props.getFamilyNotices(param);
      this.setState({ familyId: familyId });
    } else {
      this.getFamilyDetails(param, familyId);
    }
  };

  getPostDetails = (param) => {
    let data = {
      postId: param,
      profilePeopleId: this.state.peopleId,
    };
    axiosPost(URL.getAssignmentByPostId, data, (response) => {
      let lnkCount = 0;
      const total = response.data.data.assignmentResources.length;
      if (response.status === 200) {
        response.data.data.assignmentResources.map((data) => {
          if (data.fileType === "LNK") {
            lnkCount++;
          }
        });
        this.setState(
          {
            assignmentResource: total - lnkCount,
            assignmentLink: lnkCount,
            assignmentInfo: response.data.data,
          },
          () => {
            // if(this.state.peopleId)
            let dataParam = {
              username: this.props.username,
            };
            if (
              localStorage.getItem("peopleId") == this.state.peopleId &&
              userRoleCode.roleStudent == userRole
            ) {
              let params = {
                assignmentId: this.state.assignmentInfo.id,
              };
              axiosPost(URL.viewAssignment, params, (response) => { });
            }
          }
        );
      }
    });
  };

  getFamilyDetails = (param, familyId) => {
    let data = {
      familyId: familyId,
    };
    let count = 0;
    axiosPost(URL.selectFamilyMembers, data, (response) => {
      if (response.status === 200) {
        let peopleId = localStorage.getItem("peopleId");
        let datas = response.data.data;
        if (datas.length > 0) {
          datas.forEach((el) => {
            if (el.peopleId === parseInt(peopleId)) {
              count++;
              localStorage.setItem("profilePeopleId", peopleId);
            }
          });
        }
        if (count === 0) {
          param.peopleId = localStorage.getItem("peopleId");
          this.setState({ memberOfFamily: false, peopleId: peopleId });
          this.props.getNotices(param, this.clearAndUpdateInterval);
        } else {
          param.familyId = familyId;
          this.props.getFamilyNotices(param);
          this.setState({ memberOfFamily: true, familyId: familyId });
        }
      }
    });
  };

  personalNoticeConfig = () => {
    let userRoles = userRole;
    let param = {
      postType: postType.noticeCode,
      createdAt: this.state.noticeDate,
    };
    if (
      userRoles === userRoleCode.roleAdmin ||
      userRoles === userRoleCode.roleStaff
    ) {
      if (!this.props.username) {
        param.peopleId = localStorage.getItem("peopleId");
        this.setState({ peopleId: localStorage.getItem("peopleId") });
        this.props.getNotices(param, this.clearAndUpdateInterval);
      } else {
        let data = {
          username: this.props.username,
        };
        axiosPost(URL.getLoggedInUser, data, (response) => {
          if (response.status === 200) {
            param.peopleId = response.data.data.peopleId;
            this.setState({ peopleId: response.data.data.peopleId });
            this.props.getNotices(param, this.clearAndUpdateInterval);
          }
        });
      }
    } else {
      if (userRole === userRoleCode.roleStudentContact) {
        axiosPost(
          URL.selectFamilyMembers,
          { familyId: localStorage.getItem("familyId") },
          (response) => {
            if (response.status === 200) {
              let profilePeopleId = this.props.profilePeopleId;
              let count = 0;
              if (response.data.data.length > 0) {
                response.data.data.forEach((el) => {
                  if (el.peopleId === parseInt(profilePeopleId)) {
                    count++;
                  }
                });
              }
              if (count > 0) {
                param.peopleId = profilePeopleId;
                this.setState({ peopleId: profilePeopleId });
                this.props.getNotices(param, this.clearAndUpdateInterval);
              } else {
                this.setState({ displayNoticeSection: false });
              }
            }
          }
        );
      } else {
        param.peopleId = localStorage.getItem("peopleId");
        this.setState({ peopleId: localStorage.getItem("peopleId") });
        this.props.getNotices(param, this.clearAndUpdateInterval);
      }
    }
  };

  componentDidMount() {
    this.callNoticeFunctions();
    this.callNoticeInterval = setInterval(
      this.callNoticeFunctions,
      this.state.counter
    );
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.callNoticeInterval);
  }

  callNoticeFunctions = () => {
    if (!this.state.modal) {
      if (this.props.groupPage) {
        this.groupNoticeConfig();
      } else if (this.props.familyPage) {
        this.familyNoticeConfig();
      } else {
        this.personalNoticeConfig();
      }
    }
  };

  callNoticeFunctionForModeration = () => {
    if (this.props.groupPage) {
      this.groupNoticeConfig();
    } else if (this.props.familyPage) {
      this.familyNoticeConfig();
    } else {
      this.personalNoticeConfig();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.notices, this.props.notices)) {
      this.setState({
        notices: this.props.notices,
        totalNoofNotice: this.props.notices ? this.props.notices.length : 0,
        noOfnotice: 1,
        initialSlide: 0,
        noticeSpinner: this.props.noticeSpinner,
      });
    }
    if (prevProps.noticeSpinner !== this.props.noticeSpinner) {
      this.setState({
        noticeSpinner: this.props.noticeSpinner,
      });
    }
    if (
      prevProps.peopleId !== this.props.peopleId ||
      prevProps.familyId !== this.props.familyId
    ) {
      if (this.props.groupPage) {
        this.groupNoticeConfig();
      } else if (this.props.familyPage) {
        this.familyNoticeConfig();
      } else {
        this.personalNoticeConfig();
      }
    }
  }

  handleNoticeDateChange = (e) => {
    this.setState({ noticeDate: e.target.value, notices: [] }, function () {
      let param = {
        postType: postType.noticeCode,
        createdAt: this.state.noticeDate,
      };
      this.props.getNoticeSpinner(true);
      if (this.state.groupName) {
        param.groupName = this.state.groupName;
        this.props.getGroupNotices(param);
      }
      if (this.state.familyId) {
        param.familyId = this.state.familyId;
        this.props.getFamilyNotices(param);
      }
      if (this.state.peopleId) {
        param.peopleId = this.state.peopleId;
        this.props.getNotices(param, this.clearAndUpdateInterval);
      }
    });
  };

  sliderSwipe = (index) => {
    let noOfnotice = index + 1;
    if (noOfnotice > this.state.totalNoofNotice) {
      noOfnotice = 1;
    }
    this.setState({ noOfnotice });
  };

  toggleModal = async (e, notice, action, isAssignment) => {
    if (notice && notice.post?.postType === postType.videoConference) {
      let allPromises = [];

      let param = {
        postId: notice.post.id,
        profilePeopleId: this.state.peopleId,
      };
      let selectVideoConferencePostPromise = new Promise((resolve, reject) => {
        axiosPost(URL.videoConferenceSelectByPostId, param, (res) => {
          if (res.status === 200) {
            resolve(res.data.data);
          }
        });
      });
      allPromises.push(selectVideoConferencePostPromise);
      let replaceData = await selectVideoConferencePostPromise;

      notice.post.heading = notice.post.heading.replace(
        "[sessionName]",
        replaceData?.sessionName
      );
    }

    if (action === "open") {
      this.setState({ notice: notice });
    }
    if (isAssignment === "assignment") {
      this.getPostDetails(notice.id);
      this.setState({ isAssignment: true });
    }
    if (isAssignment === "") {
      this.setState({ isAssignment: false });
    }
    this.setState({ modal: !this.state.modal });
  };

  closeModal = () => {
    this.setState({ modal: !this.state.modal }, function () {
      this.callNoticeFunctions();
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.type === "checked" ? e.target.checked : e.target.value;
    if (name === "mark") {
      if (value > this.state.point || value == 0) {
        swal({
          title: "Warning",
          text:
            "Point must be less than " +
            this.state.point +
            " and greater than 0.",
          allowOutsideClick: false,
          closeOnClickOutside: false,
        });
      }
    }
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (e) => {
    let name = e.target.name;
    let value = e.target.type === "checked" ? e.target.checked : e.target.value;
    this.setState({ [name]: value }, function () {
      if (
        this.state.searchByDate !== "" &&
        this.state.searchByDate !== "none" &&
        this.state.searchByDate !== "academicYear"
      ) {
        let dates = this.state.searchByDate.split(",");
        let fromDate = moment(dates[0]).format("YYYY-MM-DD");
        let toDate = moment(dates[1]).format("YYYY-MM-DD");
        if (dates.length === 1) {
          this.setState({ fromDate: fromDate, toDate: fromDate });
        } else {
          this.setState({ fromDate: fromDate, toDate: toDate });
        }
      }
    });
  };

  viewNotice = async (e) => {
    e && e.preventDefault();
    if (this.state.searchByDate !== "" || this.state.searchText !== "") {
      let data;
      if (this.state.searchByDate == moment().format("YYYY-MM-DD")) {
        data = {
          postType: postType.noticeCode,
          searchText: this.state.searchText,
          termDate: this.state.searchByDate,
        };
        if (this.state.groupName) {
          data.groupName = this.state.groupName;
        }
        if (this.state.familyId) {
          data.familyId = this.state.familyId;
        }
        if (this.state.peopleId) {
          data.peopleId = this.state.peopleId;
        }
      } else if(this.state.searchByDate =="academicYear"){
        data = {
          postType: postType.noticeCode,
          searchText: this.state.searchText,
          currentAcademicYear: true,
        };
      } else {
        data = {
          postType: postType.noticeCode,
          searchText: this.state.searchText,
          dateFrom:
            this.state.searchByDate === "none" ? "" : this.state.fromDate,
          dateTo: this.state.searchByDate === "none" ? "" : this.state.toDate,
        };
        if (this.state.groupName) {
          data.groupName = this.state.groupName;
        }
        if (this.state.familyId) {
          data.familyId = this.state.familyId;
        }
        if (this.state.peopleId) {
          data.peopleId = this.state.peopleId;
        }
      }
      this.props.getNoticeSpinner(true);
      let allPromises = [];
      let searchNoticePromise = new Promise((resolve, reject) => {
        axiosPost(URL.searchNotice, data, (response) => {
          if (response.status === 200) {
            resolve(response.data.data);
          }
        });
      });
      allPromises.push(searchNoticePromise);
      let notices = await searchNoticePromise;
      notices.forEach(async (notice, idx) => {
        if (notice.postType === postType.assignment) {
          let param = {
            postId: notice.id,
            profilePeopleId: this.state.peopleId,
          };
          let selectAssignmentPostPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getAssignmentByPostId, param, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });
          allPromises.push(selectAssignmentPostPromise);
          let replaceData = await selectAssignmentPostPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.textContent = notice.textContent.replace(
            "[peopleName]",
            replaceData?.peopleName ? replaceData?.peopleName : ""
          );
          notice.isTargettedStudent = replaceData.isTargettedStudent;
          notice.assignmentStatus = replaceData.assignmentStatus;
          notice.isAudience = replaceData.isAudience;
        }
        if (notice.postType === postType.videoConference) {
          let param = {
            postId: notice.id,
            profilePeopleId: this.state.peopleId,
          };
          let selectVideoConferencePostPromise = new Promise(
            (resolve, reject) => {
              axiosPost(URL.videoConferenceSelectByPostId, param, (res) => {
                if (res.status === 200) {
                  resolve(res.data.data);
                }
              });
            }
          );
          allPromises.push(selectVideoConferencePostPromise);
          let replaceData = await selectVideoConferencePostPromise;
          let startDateTime = replaceData?.startDateTime
            ? moment(replaceData?.startDateTime).format(
              "Do MMMM, YYYY [at] hh:mm A"
            )
            : null;
          let endDateTime = replaceData?.endDateTime
            ? moment(replaceData?.endDateTime).format(
              "Do MMMM, YYYY [at] hh:mm A"
            )
            : null;
          notice.heading = notice.heading
            ? notice.heading.replace("[sessionName]", replaceData?.sessionName)
            : null;
          notice.textContent = notice.textContent.replace(
            "[peopleName]",
            replaceData?.peopleName
          );
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message
          );
          notice.textContent = notice.textContent.replace(
            "[sessionName]",
            replaceData?.sessionName
          );
          notice.textContent = notice.textContent.replace(
            "[startDateTime]",
            startDateTime
          );
          notice.textContent = notice.textContent.replace(
            "[endDateTime]",
            endDateTime
          );
          notice.textContent = notice.textContent.replace(
            "[hostName]",
            replaceData?.hostName
          );
        }
        if (notice.postType === postType.mcq) {
          let paramMCQ = {
            postId: notice.id,
          };
          let selectMCQPostPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getMcqByPostId, paramMCQ, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });
          allPromises.push(selectMCQPostPromise);
          let replaceData = await selectMCQPostPromise;
          notice.heading = notice.heading.replace(
            "[topic]",
            replaceData?.topic ? replaceData?.topic : ""
          );
          notice.textContent = notice.textContent.replace(
            "[peopleName]",
            replaceData?.peopleName ? replaceData?.peopleName : ""
          );
          notice.textContent = notice.textContent.replace(
            "[topic]",
            replaceData?.topic ? replaceData?.topic : ""
          );
        }
        if (notice.postType === postType.profilePictureNotice) {
          let paramProfilePicture = {
            postId: notice.id,
            profilePeopleId: this.state.peopleId,
          };
          let selectProfilePicPromise = new Promise((resolve, reject) => {
            axiosPost(
              URL.selectProfilePictureNoticeById,
              paramProfilePicture,
              (res) => {
                if (res.status === 200) {
                  resolve(res.data.data);
                }
              }
            );
          });
          allPromises.push(selectProfilePicPromise);
          let replaceData = await selectProfilePicPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;
          notice.moderatedStatus = replaceData?.moderatedStatus
            ? replaceData.moderatedStatus
            : "";
          notice.profilePictureForModeration =
            replaceData?.profilePictureForModeration
              ? replaceData.profilePictureForModeration
              : "";
        }
        if (notice.postType === postType.coverPictureNotice) {
          let coverPictureParam = {
            postId: notice.id,
            profilePeopleId: this.state.peopleId,
          };
          let selectCoverPicPromise = new Promise((resolve, reject) => {
            axiosPost(
              URL.selectCoverPictureNoticeById,
              coverPictureParam,
              (res) => {
                if (res.status === 200) {
                  resolve(res.data.data);
                }
              }
            );
          });
          allPromises.push(selectCoverPicPromise);
          let replaceData = await selectCoverPicPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.coverPicturesForModeration =
            replaceData?.coverPicturesForModeration
              ? replaceData?.coverPicturesForModeration
              : "";
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;
        }
        if (notice.postType === postType.postModeration) {
          let paramPost = {
            postId: notice.id,
            profilePeopleId: this.state.peopleId,
          };
          let selectPostPromise = new Promise((resolve, reject) => {
            axiosPost(URL.selectPostNoticeById, paramPost, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });
          allPromises.push(selectPostPromise);
          let replaceData = await selectPostPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;
          notice.moderatedStatus = replaceData?.moderatedStatus
            ? replaceData.moderatedStatus
            : "";
          notice.post = replaceData;
          if (notice.post.contents.length > 0) {
            notice.post.contents.forEach((content) => {
              if (content.contentTypeCode === "LNK" && content.content !== "") {
                // let videoId = "";
                if (
                  content.content.includes("youtube") ||
                  content.content.includes("youtu.be")
                ) {
                  let contentObj = youtubeLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (
                  content.content.includes("dailymotion") ||
                  content.content.includes("dai.ly")
                ) {
                  let contentObj = dailyMotionLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (content.content.includes("vimeo")) {
                  let contentObj = vimeoLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else if (
                  content.content.includes("slideshare") &&
                  content.youtubeTitle !== undefined
                ) {
                  let contentObj = slideshareLink(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                } else {
                  let contentObj = defaultLinkURL(content.content);
                  content.youtubeTitle = contentObj.youtubeTitle;
                  content.thumbnail = contentObj.thumbnail;
                }
              }
            });
          }
        }
        if (notice.postType === postType.academicPostCommentModeration) {
          let paramobj = {
            id: notice.id,
            profilePeopleId: this.state.peopleId,
          };
          let postAcademicCommmentPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getAcademicRepoCommentByPost, paramobj, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });

          allPromises.push(postAcademicCommmentPromise);

          let replaceData = await postAcademicCommmentPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;

          notice.moderatedStatus = replaceData?.academicRepoComment
            ?.moderatedStatus
            ? replaceData.academicRepoComment.moderatedStatus
            : "";
        }

        if (notice.postType === postType.postCommentModeration) {
          let paramobj = {
            id: notice.id,
            profilePeopleId: this.state.peopleId,
          };
          let postCommmentPromise = new Promise((resolve, reject) => {
            axiosPost(URL.getCommentByPost, paramobj, (res) => {
              if (res.status === 200) {
                resolve(res.data.data);
              }
            });
          });

          allPromises.push(postCommmentPromise);

          let replaceData = await postCommmentPromise;
          notice.textContent = notice.textContent.replace(
            "[message]",
            replaceData?.message ? replaceData?.message : ""
          );
          notice.canModerate = replaceData?.canModerate
            ? replaceData?.canModerate
            : false;

          notice.moderatedStatus = replaceData?.postComment?.moderatedStatus
            ? replaceData.postComment.moderatedStatus
            : "";
        }
      });
      Promise.all(allPromises).then((data) => {
        this.props.getNoticeSpinner(false);
        this.setState({
          notices: notices,
          totalNoofNotice: notices.length,
          minimize: false,
        });
      });
    } else {
      swal({
        title: "Warning",
        text: "Date option or SearchText should not be empty",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    }
  };

  getComments = (id) => {
    axiosPost(URL.getCommentByKey, { postId: id }, (response) => {
      if (response.status === 200) {
        let comments = response.data.data;
        comments.forEach((el) => {
          el.editable = false;
        });
        this.setState({ comments });
      }
    });
  };

  toggleEditable = (idx) => {
    let comments = [...this.state.comments];
    comments.forEach((el) => {
      el.editable = false;
    });
    comments[idx].editable = true;
    this.setState({
      comments: comments,
      newCommentValue: comments[idx].commentValue,
    });
  };
  noticeModalBody = () => {
    // this.state.notice.forEach((not) => {
    //   not.heading = newNotice[0].heading;
    // });

    return (
      <NoticeModalBody
        notice={this.state.notice}
        comments={this.state.comments}
        getComments={this.getComments}
        deleteComment={this.deleteComment}
        toggleEditable={this.toggleEditable}
        newCommentValue={this.state.newCommentValue}
        handleChange={this.handleChange}
        updateComment={this.updateComment}
        handleJoinConference={this.handleJoinConference}
        callNoticeFunctions={this.callNoticeFunctions}
        callNoticeFunctionForModeration={this.callNoticeFunctionForModeration}
        handleModeration={this.handleModeration}
        closeModal={this.closeModal}
        profilePeopleId={this.props.profilePeopleId}
        peopleId={this.props.peopleId}
        isAssignment={this.state.isAssignment}
        assignmentInfo={this.state.assignmentInfo}
        roomId=""
        userName=""
        password=""
        isHost=""
      ></NoticeModalBody>
    );
  };
  postComment = (notice) => {
    axiosPost(
      URL.postComment,
      {
        postId: notice.id,
        commentValue: this.state.commentValue,
        contentTypeCode: "TXT",
      },
      (response) => {
        if (response.status === 200) {
          if (response.data.data.moderatedStatus === "PENDING") {
            swal(MESSAGEHEADER.success, "Post comment sent for moderation", "");
          } else {
            swal({
              title: MESSAGEHEADER.success,
              text: "Successfully Commented",
              allowOutsideClick: false,
              closeOnClickOutside: false,
            });
          }
          this.getComments(notice.id);
          this.setState({ commentValue: "" });
        }
      }
    );
  };

  deleteComment = (id) => {
    axiosPost(
      URL.deleteComment,
      {
        id: id,
        deleteStatus: true,
      },
      (response) => {
        if (response.status === 200) {
          this.getComments(this.state.notice.id);
        }
      }
    );
  };
  updateComment = (commentValue, comment) => {
    axiosPost(
      URL.updateComment,
      {
        id: comment.id,
        contentTypeCode: comment.contentTypeCode,
        postId: comment.postId,
        peopleId: comment.peopleId,
        commentValue: this.state.newCommentValue,
      },
      (response) => {
        if (response.status === 200) {
          this.getComments(comment.postId);
        }
      }
    );
  };

  noticeModalFooter = () => {
    const { notice } = this.state;
    return (
      <div className="row">
        <div className="col-md-2 tt-modalMobileNoticeFooter">
          <strong>Add a comment</strong>
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            name="commentValue"
            value={this.state.commentValue}
            onChange={this.handleChange}
          ></input>
        </div>
        <div
          className="col-md-2 tt-commentMobileButton"
          style={{ marginTop: "3px" }}
        >
          <button
            className="tt-button tt-button-primary"
            onClick={(e) => this.postComment(notice)}
          >
            Comment
          </button>
        </div>
      </div>
    );
  };

  minimizeGroupContainer = () => {
    this.setState({ minimize: !this.state.minimize });
  };

  toggleNoticeModal = () => {
    this.setState({ noticeModal: !this.state.noticeModal }, function () {
      if (!this.state.noticeModal) {
        this.callNoticeFunctions();
      }
    });
  };

  createNotice = () => {
    this.toggleNoticeModal();
  };

  displayKeywordModal = (e, groups) => {
    this.setState({
      keywordModal: !this.state.keywordModal,
      targetGroups: groups,
    });
  };

  toggleKeywordModal = () => {
    this.setState({ keywordModal: !this.state.keywordModal });
  };

  keywordModalBody = () => {
    return (
      <ol>
        {this.state.targetGroups
          ? this.state.targetGroups.map((group, idx) => {
            return <li key={idx}>{group.name}</li>;
          })
          : null}
      </ol>
    );
  };
  handleSubmitAssignment = (notice) => {
    let param = {
      postId: notice.id,
    };
    axiosPost(URL.getAssignmentByPostId, param, (response) => {
      if (response.status == 200) {
        this.setState({
          assignmentId: response.data.data.id,
          submissionModal: !this.state.submissionModal,
        });
      }
    });
  };
  toggleSubmissionModal = () => {
    this.setState({
      submissionModal: !this.state.submissionModal,
      assignmentId: null,
    });
  };

  toggleSubmittedResourceModal = () => {
    this.setState({
      submittedResourceModal: !this.state.submittedResourceModal,
    });
  };
  saveResources = (contents, links) => {
    this.setState({ contents: contents, links: links });

    let formData = new FormData();
    for (const key of Object.keys(contents)) {
      formData.append("files", contents[key]);
    }
    if (contents.length <= 0) {
      formData.append("files", []);
    }

    formData.append(
      "jsonData",
      JSON.stringify({
        assignmentId: this.state.assignmentId,
        links: links,
      })
    );
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.submitStudentAssignment,
        formData,
        (res) => {
          if (res.status === 200) {
            swal({
              title: "Success",
              text: "Successfully submitted",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            this.setState({ submissionModal: false });
            if (this.props.groupPage) {
              this.groupNoticeConfig();
            } else if (this.props.familyPage) {
              this.familyNoticeConfig();
            } else {
              this.personalNoticeConfig();
            }
            draggable();
            this.setState({
              contents: [],
              links: [],
              assignmentId: null,
              showSpinner: false,
            });
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayErrorAlert(error);
          // this.resetState();
        }
      );
    });
    //call api and update list
    // this.handleAddResourceModal();
  };

  handleViewSubmittedAssignment = (id) => {
    let param = {
      postId: id,
    };
    axiosPost(URL.getAssignmentByPostId, param, (response) => {
      if (response.status == 200) {
        this.setState({
          assignmentId: response.data.data.id,
        });
        let params = {
          assignmentId: response.data.data.id,
          peopleId: this.state.peopleId,
        };
        axiosPost(URL.viewStudentAssignmentResources, params, (response) => {
          if (response.status == 200) {
            this.setState({ submittedResources: response.data.data });
            this.toggleSubmittedResourceModal();
          }
        });
      }
    });
  };

  handleAssignmentDropBoxClick = (id) => {
    let param = {
      postId: id,
    };
    if (userRole == userRoleCode.roleStudentContact) {
      axiosPost(URL.getAssignmentByPostId, param, (response) => {
        if (response.status == 200) {
          this.setState({
            assignmentId: response.data.data.id,
          });

          let params = {
            assignmentId: response.data.data.id,
            familyId: this.props.familyId,
          };

          axiosPost(
            URL.selectAssignmentStudentsByFamilyId,
            params,
            (response) => {
              if (response.status == 200) {
                this.setState({ assignmentStudent: response.data.data });
                this.toggleAssignmentDropboxModal();
              }
            }
          );
        }
      });
    } else {
      axiosPost(URL.getAssignmentByPostId, param, (response) => {
        if (response.status == 200) {
          this.setState({
            assignmentId: response.data.data.id,
          });

          let params = {
            assignmentId: response.data.data.id,
          };

          axiosPost(URL.selectAssignmentStudents, params, (response) => {
            if (response.status == 200) {
              this.setState({ assignmentStudent: response.data.data });
              this.toggleAssignmentDropboxModal();
            }
          });
        }
      });
    }
  };
  toggleAssignmentDropboxModal = () => {
    this.setState({
      assignmentDropboxModal: !this.state.assignmentDropboxModal,
    });
  };
  // handleViewSubmittedResources = (assignmentId, studentId, studentStatus) => {
  //   // this.setState({
  //   //   currentStudentStatus: studentStatus,
  //   //   currentAdmissionId: studentId,
  //   //   currentAssignmentId: assignmentId,
  //   // });
  //   let params = {
  //     assignmentId: assignmentId,
  //     admissionId: studentId,
  //   };
  //   axiosPost(URL.viewStudentAssignmentResources, params, (response) => {
  //     if (response.status == 200) {
  //       this.setState({ submittedResources: response.data.data });
  //       this.toggleSubmittedResourceModal();
  //     }
  //   });
  // };

  handleViewSubmittedResources = (
    assignmentId,
    studentId,
    studentStatus,
    point
  ) => {
    this.setState({
      currentStudentStatus: studentStatus,
      currentAdmissionId: studentId,
      currentAssignmentId: assignmentId,
      point: point,
    });
    let params = {
      assignmentId: assignmentId,
      admissionId: studentId,
    };
    axiosPost(URL.viewStudentAssignmentResources, params, (response) => {
      if (response.status == 200) {
        this.setState({ submittedResources: response.data.data });
        this.toggleSubmittedResourceModal();
      }
    });
  };
  handleJoinConference = (id) => {
    let param = {
      postId: id,
      joinRequest: true,
    };
    axiosPost(
      URL.videoConferenceSelectByPostId,
      param,
      (response) => {
        if (response.status === 200 && response.data != null) {
          const roomId = response.data.data.jitsiRoomId;
          const userName = response.data.data.peopleName;
          const password = response.data.data.password;
          const createdByPeopleId = response.data.data.createdByPeopleId;
          const hasStarted = response.data.data.hasStarted;
          const isHost =
            localStorage.getItem("peopleId") == createdByPeopleId
              ? true
              : false;
          if (isHost && !hasStarted) {
            //update video conference
            let updateParam = response.data.data;
            updateParam.hasStarted = true;
            axiosPost(URL.videoConferenceUpdate, updateParam, (response) => { });
          }
          if (isHost || hasStarted) {
            // const link =
            //   "https://techarttrekkies.com/jitsi-demo/index.html?getMeetingId=" +
            //   roomId +
            //   "&getDispNme=" +
            //   userName +
            //   "&getPassword=" +
            //   password +
            //   "&isHost=" +
            //   isHost;
            // window.open(link);

            this.setState(
              {
                roomId: roomId,
                userName: userName,
                password: password,
                isHost: isHost,
                videoConferenceId: id,
              },
              password !== "" && password !== null
                ? this.toggleJitsiModal()
                : alert("Password is Required to join the room")
            );
          } else {
            displayMessage(
              "Contact Host Provider",
              "Video Conference has not been started by the host"
            );
          }
        }
      },
      (err) => {
        displayErrorMessage(err);
      }
    );
  };

  toggleJitsiModal = () => {
    this.setState({ jitsiModel: !this.state.jitsiModel });
  };

  //MCQ Start Test
  startTest = (e, id) => {
    let data = {
      id: id,
    };
    axiosPost(URL.getMcqById, data, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        if (datum.mcqQuestions.length > 0) {
          datum.mcqQuestions.forEach((ques) => {
            if (ques.mcqQuestionOptions.length > 0) {
              ques.mcqQuestionOptions.forEach((opt) => {
                opt.isSelected = false;
              });
            }
          });
        }

        this.setState(
          { mcqTest: datum, minute: datum.timer, seconds: 0 },
          function () {
            let peopleMcq = datum.peopleMcq;
            if (peopleMcq !== null) {
              this.setState({ isSubmitted: peopleMcq?.isSubmitted });
              if (datum.mcqQuestions.length > 0) {
                datum.mcqQuestions.forEach((ques) => {
                  if (ques.mcqQuestionOptions.length > 0) {
                    ques.mcqQuestionOptions.forEach((opt) => {
                      peopleMcq &&
                        peopleMcq.peopleMcqRecords &&
                        peopleMcq.peopleMcqRecords.forEach((el) => {
                          if (el.mcqQuestionId === ques.id) {
                            if (el.mcqQuestionOptionId === opt.id) {
                              opt.isSelected = true;
                            }
                          }
                        });
                    });
                  }
                });
              }
            }
            this.setState({ mcqTest: datum }, function () {
              this.setState({ mcqModal: !this.state.mcqModal });
            });
          }
        );
      }
    });
  };

  startTimerTest = () => {
    if (this.state.mcqTest.testTypeId === 1) {
      this.startInterval = setInterval(this.startTimer, 1000);
    }
  };

  startTimer = () => {
    this.setState({ seconds: this.state.seconds - 1 }, function () {
      if (this.state.seconds === -1) {
        this.setState({ minute: this.state.minute - 1 });
        this.setState({ seconds: 59 });
      }
      if (this.state.minute === 0 && this.state.seconds === 0) {
        clearInterval(this.startInterval);
        swal({
          title: "Time over",
          text: "Your time is up. Your given answers has been submitted.",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              dangerMode: true,
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
          alloswOutsideClick: false,
        }).then((willDelete) => {
          if (willDelete) {
            this.handleSubmit();
          } else {
            if (this.props.groupPage) {
              this.groupNoticeConfig();
            } else if (this.props.familyPage) {
              this.familyNoticeConfig();
            } else {
              this.personalNoticeConfig();
            }
            this.setState({
              isSubmitted: false,
              mcqModal: !this.state.mcqModal,
            });
          }
        });
        // this.handleSubmit();
        // this.setState({ minute: 15, seconds: 59 });
      }
    });
  };

  toggleMcqModal = () => {
    clearInterval(this.startInterval);
    this.setState({ mcqModal: !this.state.mcqModal, isSubmitted: false });
  };

  handleOptionClick = (e, quesId, oIdx) => {
    let mcqTest = this.state.mcqTest;
    if (mcqTest.mcqQuestions.length > 0) {
      mcqTest.mcqQuestions.forEach((ques) => {
        if (ques.id === quesId) {
          ques.mcqQuestionOptions.forEach((opt, idx) => {
            if (idx === oIdx) {
              opt.isSelected = true;
            } else {
              opt.isSelected = false;
            }
          });
        }
      });
    }
    this.setState({ mcqTest }, function () {
      let data = {
        mcqTestId: this.state.mcqTest.id,
        isSubmitted: this.state.isSubmitted,
      };
      let peopleMcqRecords = [];
      if (mcqTest.mcqQuestions.length > 0) {
        mcqTest.mcqQuestions.forEach((ques) => {
          ques.mcqQuestionOptions.forEach((opt, idx) => {
            if (opt.isSelected) {
              peopleMcqRecords.push({
                mcqQuestionId: ques.id,
                mcqQuestionOptionId: opt.id,
              });
            }
          });
        });
      }
      data.peopleMcqRecords = peopleMcqRecords;
      axiosPost(URL.insertMcqOption, data, (response) => { });
    });
  };

  submitMcqTest = (e) => {
    let mcqTest = this.state.mcqTest;
    let unanswered = 0;
    if (mcqTest.mcqQuestions.length > 0) {
      mcqTest.mcqQuestions.forEach((ques) => {
        let count = 0;
        ques.mcqQuestionOptions.forEach((opt, idx) => {
          if (opt.isSelected === false) {
            count++;
          }
        });
        if (count === ques.mcqQuestionOptions.length) {
          unanswered++;
        }
      });
    }
    if (unanswered === 0) {
      this.handleSubmit();
    } else {
      swal({
        title: "Warning",
        text: `${unanswered} questions ${unanswered === 1 ? "hasn't" : "haven't"
          } been answered yet. Do you want to submit answers anyway?`,
        buttons: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.handleSubmit();
        }
      });
    }
  };

  handleSubmit = () => {
    this.setState({ isSubmitted: true }, function () {
      let data = {
        isSubmitted: this.state.isSubmitted,
        mcqTestId: this.state.mcqTest.id,
        peopleMcqRecords: [],
      };
      axiosPost(URL.insertMcqOption, data, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: "MCQ successfully submitted",
            alloswOutsideClick: false,
            closeOnClickOutside: false,
          });
          if (this.props.groupPage) {
            this.groupNoticeConfig();
          } else if (this.props.familyPage) {
            this.familyNoticeConfig();
          } else {
            this.personalNoticeConfig();
          }
          this.setState({ isSubmitted: false, mcqModal: !this.state.mcqModal });
        }
      });
    });
  };

  //MCQ view Result
  openResult = (e, mcq) => {
    let url = "";
    let data = {};
    let creator = false;
    if (mcq.creatorPeopleId === parseInt(localStorage.getItem("peopleId"))) {
      url = URL.getAllStudentMCQResultList;
      data.mcqTestId = mcq.mcqTestId;
      creator = true;
    } else {
      url = URL.getStudentMCQResultList;
      data.mcqTestId = mcq.mcqTestId;
      data.peopleId = parseInt(localStorage.getItem("peopleId"));
    }
    axiosPost(url, data, (response) => {
      if (response.status === 200) {
        if (creator) {
          let mcqStudentList = response.data.data;
          let newMcqStudentList = [];
          if (mcqStudentList.length > 0) {
            mcqStudentList.forEach((el) => {
              if (el.isSubmitted === true) {
                newMcqStudentList.push(el);
              }
            });
            mcqStudentList.forEach((el) => {
              if (el.isSubmitted === false) {
                newMcqStudentList.push(el);
              }
            });
          }
          this.setState({
            mcqStudentListModal: !this.state.mcqStudentListModal,
            mcqStudentList: newMcqStudentList,
          });
        } else {
          let correctAnswer = 0;
          let unanswered = 0;
          let incorrect = 0;
          let fullMarks = 0;
          let obtainedMarks = 0;
          let datum = response.data.data;
          if (datum.length > 0) {
            datum.forEach((el) => {
              fullMarks = parseInt(el.fullMarks);

              if (
                el.selectedOptionId === undefined ||
                el.selectedOptionId === null
              ) {
                unanswered++;
              } else {
                if (el.mcqQuestionOptions.length > 0) {
                  el.mcqQuestionOptions.forEach((opt) => {
                    if (el.selectedOptionId === opt.id && opt.isCorrect) {
                      correctAnswer++;
                      obtainedMarks = obtainedMarks + parseInt(el.marks);
                    }
                    if (
                      el.selectedOptionId === opt.id &&
                      opt.isCorrect === false
                    ) {
                      incorrect++;
                    }
                  });
                }
              }
            });
          }
          let totalAnswered = correctAnswer + incorrect;
          this.setState(
            {
              mcqResult: datum,
              correctAnswer: correctAnswer,
              incorrect: incorrect,
              unanswered: unanswered,
              totalAnswered: totalAnswered,
              fullMarks,
              obtainedMarks,
            },
            function () {
              this.setState({ mcqResultModal: !this.state.mcqResultModal });
            }
          );
        }
      }
    });
  };

  toggleMcqStudentListModal = () => {
    this.setState({ mcqStudentListModal: !this.state.mcqStudentListModal });
  };

  viewStudentResult = (e, student) => {
    let data = {
      mcqTestId: student.mcqTestId,
      peopleId: student.peopleId,
    };
    axiosPost(URL.getStudentMCQResultList, data, (response) => {
      if (response.status === 200) {
        let correctAnswer = 0;
        let unanswered = 0;
        let incorrect = 0;
        let fullMarks = 0;
        let obtainedMarks = 0;
        let datum = response.data.data;
        if (datum.length > 0) {
          datum.forEach((el) => {
            fullMarks = parseInt(el.fullMarks);

            if (
              el.selectedOptionId === undefined ||
              el.selectedOptionId === null
            ) {
              unanswered++;
            } else {
              if (el.mcqQuestionOptions.length > 0) {
                el.mcqQuestionOptions.forEach((opt) => {
                  if (el.selectedOptionId === opt.id && opt.isCorrect) {
                    correctAnswer++;
                    obtainedMarks = !!el.marks
                      ? obtainedMarks + parseInt(el.marks)
                      : obtainedMarks;
                  }
                  if (
                    el.selectedOptionId === opt.id &&
                    opt.isCorrect === false
                  ) {
                    incorrect++;
                  }
                });
              }
            }
          });
        }
        let totalAnswered = correctAnswer + incorrect;
        this.setState(
          {
            mcqResult: datum,
            correctAnswer: correctAnswer,
            incorrect: incorrect,
            unanswered: unanswered,
            totalAnswered: totalAnswered,
            obtainedMarks,
            fullMarks,
          },
          function () {
            this.setState({ mcqResultModal: !this.state.mcqResultModal });
          }
        );
      }
    });
  };

  toggleMcqResultModal = () => {
    this.setState({ mcqResultModal: !this.state.mcqResultModal });
  };

  //AcceptRejectCoverPicture

  handleModeration = (e, id, status) => {
    let data = [
      {
        id: id,
        moderatedStatus: status,
      },
    ];

    axiosPost(URL.acceptRejectCP, data, (response) => {
      if (response.status === 200) {
        let datum = response.data.data;
        let notices = this.state.notices;
        if (notices.length > 0) {
          notices.forEach((el) => {
            if (el.postType === postType.coverPictureNotice) {
              let pics = el.coverPicturesForModeration;
              if (pics.length > 0) {
                pics.forEach((cp) => {
                  if (cp.id === id) {
                    cp.moderatedStatus = datum[0].moderatedStatus;
                  }
                });
              }
            }
          });
        }
        this.setState({ notices });
      }
    });
  };

  handleAssignmentReviewBatch = (e) => {
    let params = {
      status: this.state.status,
      remarks: this.state.remarks === "" ? "No remarks" : this.state.remarks,
      assignmentId: this.state.currentAssignmentId,
      admissionId: this.state.currentAdmissionId,
      type:
        this.state.status === "Correction" || this.state.status === "Approved"
          ? "RMK"
          : "",
      batch: e,
      point: this.state.mark,
    };
    this.setState(
      {
        showSpinner: true,
      },
      () => {
        axiosPost(
          URL.updateAssignmentStudentStatus,
          params,
          (response) => {
            if (response.status == 200) {
              this.setState({
                currentStudentStatus: params.status,
                submittedResourceModal: false,
              });
              swal({
                title: "Success",
                text: "Assignment Checked Successfully",
                closeOnClickOutside: false,
                allowOutsideClick: false,
              });

              this.setState({
                showSpinner: false,
              });
              this.getStudent(this.state.currentAssignmentId);
            }
          },
          (err) => {
            displayErrorAlert(err);
            this.setState({
              showSpinner: false,
            });
          }
        );
      }
    );
  };

  getStudent = (id) => {
    this.setState({ assignmentStudent: [] });
    let params = {
      assignmentId: id,
    };

    axiosPost(URL.selectAssignmentStudents, params, (response) => {
      if (response.status == 200) {
        this.setState({ assignmentStudent: response.data.data });
      }
    });
  };

  cannotClose = () => {
    swal("Information", "Cannot close while attending the test.");
  };

  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: false,
            autoplay: true,
          },
        },
      ],
    };

    return this.state.displayNoticeSection ? (
      <>
        {this.state.showSpinner && (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait...
            </div>
          </div>
        )}

        <div
          className={
            "tt-noticeDisplay " +
            (this.state.minimize ? "minimize-Notice " : "") +
            (!this.props.familyPage &&
              !this.props.groupPage &&
              this.props.userData.userRole !== "Admin"
              ? this.props.userData.isStudent
                ? "studentNoticeHeight"
                : "staffNoticeHeight"
              : "")
          }
        >
          <div
            className="tt-group-header"
            style={{
              padding: "5px",
              marginBottom: 0,
            }}
            ref={(ref) => (this.fooRef = ref)}
            onDoubleClick={this.minimizeGroupContainer}
          >
            <div className="container">
              <div className="row">
                <div
                  className="col-md-4 tt-noticesSubData"
                  data-tip="Double click to expand and collapse this widget"
                >
                  Notice Board &nbsp;&nbsp;
                  {this.state.totalNoofNotice === 0 ? (
                    "(0 Notices)"
                  ) : (
                    <>
                      <span>
                        {/* {moment(new Date()).format("dddd, MMMM Do YYYY")} ( */}
                        (<strong>{this.state.noOfnotice}</strong> of{" "}
                        <strong>{this.state.totalNoofNotice}</strong> notices )
                      </span>
                    </>
                  )}
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    name="searchText"
                    value={this.state.searchText}
                    className="form-control"
                    placeholder="Enter search text"
                    onChange={this.handleChange}
                    onDoubleClick={(e) => e.stopPropagation()}
                  ></input>
                </div>
                <div className="col-md-3 hideNoticeButton">
                  <select
                    className="form-control"
                    name="searchByDate"
                    value={this.state.searchByDate}
                    onChange={this.handleDateChange}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    <option value="" >
                      Choose date range
                    </option>
                    <option value="none">All</option>
                    <option value={todayDate}>Today</option>
                    <option value={yesterday}>Yesterday</option>
                    <option value={dayBeforeYesterday}>
                      Day before yesterday
                    </option>
                    <option value={`${startOfWeek},${endOfWeek}`}>
                      This week
                    </option>
                    <option value={`${startOfLastWeek},${endOfLastWeek}`}>
                      Last week
                    </option>
                    <option value={`${startOfLastMonth},${endOfLastMonth}`}>
                      Last Month
                    </option>
                    <option value={moment().format("YYYY-MM-DD")}>
                      This Term/semester
                    </option>
                    <option value="academicYear">This Academic Year</option>
                    <option value={`${startOfLastMonth},${endOfLastMonth}`}>Custom Date Range</option>
                  </select>
                </div>
                <div className="col-md-auto managePaddingMargin hideNoticeButton">
                  <button
                    className="tt-button tt-button-primary tt-button-fullWidth"
                    onClick={this.viewNotice}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    Search
                  </button>
                </div>


                {!checkRolePermissions("insert-notice", "activity") ? null : (
                  <>

                    <div className="col text-right hideNoticeButton"
                      data-tip="Click to create a notice">
                      <Link
                        onClick={this.createNotice}
                        onDoubleClick={(e) => e.stopPropagation()}>

                        <FontAwesomeIcon icon={faSquarePlus} className="fa-2x" />
                      </Link>
                    </div>


                  </>
                  // <div
                  //   className="col-md-2 tt-resNoticeCss"
                  //   style={{ paddingLeft: 0, marginTop: "3px" }}
                  // >
                  //   <button
                  //     className="tt-button tt-button-primary tt-button-fullWidth"
                  //     onClick={this.createNotice}
                  //     onDoubleClick={(e) => e.stopPropagation()}
                  //   >
                  //     Create Notice
                  //   </button>
                  // </div>
                )}
              </div>
              <div className="row mt-2 mobileNoticeButton">
                <div className="col managePaddingMargin">
                  <select
                    className="form-control"
                    name="searchByDate"
                    value={this.state.searchByDate}
                    onChange={this.handleDateChange}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    <option value="">
                      Choose date range
                    </option>
                    <option value="none">All</option>
                    <option value={todayDate}>Today</option>
                    <option value={yesterday}>Yesterday</option>
                    <option value={dayBeforeYesterday}>
                      Day before yesterday
                    </option>
                    <option value={`${startOfWeek},${endOfWeek}`}>
                      This week
                    </option>
                    <option value={`${startOfLastWeek},${endOfLastWeek}`}>
                      Last week
                    </option>
                    <option value={`${startOfLastMonth},${endOfLastMonth}`}>
                      Last Month
                    </option>
                    <option value={moment().format("YYYY-MM-DD")}>
                      This Term/semester
                    </option>
                    <option value="">This Academic Year</option>
                    <option value="">Custom Date Range</option>
                  </select>
                </div>
              </div>

              <div className="row mt-2 mobileNoticeButton">
                <div className="viewButton managePaddingMargin">
                  <button
                    className="tt-button tt-button-primary"
                    onClick={this.viewNotice}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    Search
                  </button>
                </div>
                {/* <div className="dateChoose">
                  <input
                    type="date"
                    className="form-control"
                    name="noticeDate"
                    value={this.state.noticeDate}
                    onChange={this.handleNoticeDateChange}
                    onDoubleClick={(e) => e.stopPropagation()}
                  ></input>
                </div> */}
              </div>
            </div>
          </div>

          {this.state.notices ? (
            this.state.notices.length > 0 ? (
              <Slider
                {...settings}
                afterChange={(index) => this.sliderSwipe(index)}
                className="noticeSlider"
              >
                {this.state.notices.map((notice, idx) => {
                  let count = 0;
                  notice.contents.forEach((el) => {
                    if (el.contentTypeCode === "LNK") count++;
                  });
                  let embeddedLinks = count;

                  return (
                    <div
                      key={idx}
                      className="tt-noticeSlider"
                      id={this.props.idForRendering + 1}
                    >
                      {/* {notice.postType === postType.assignment ? <>{this.getPostDetails(notice.id)}</> : ""} */}
                      <div
                        style={{
                          display: "flex",
                          lineHeight: "0",
                          marginBottom: "0",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <p
                            className="tt-noticeDate"
                            data-tip={moment(notice.createdAt).format("LT")}
                            data-for="dateReact"
                          >
                            {moment(notice.createdAt).format("Do MMMM, YYYY")}
                          </p>
                          {notice.remind &&
                            notice.postType !== postType.profilePictureNotice ? (
                            <div className="tt-bubble-background ml-5">
                              <div>Reminder</div>
                            </div>
                          ) : null}
                        </div>
                        <div style={{ display: "flex" }}>
                          {notice.postType === postType.profilePictureNotice ||
                            notice.postType === postType.coverPictureNotice ||
                            notice.postType === postType.postModeration ||
                            notice.postType === postType.postCommentModeration ||
                            notice.postType ===
                            postType.academicPostCommentModeration ? (
                            <div className="tt-bubble-background ml-5">
                              <div>
                                {notice.postType ===
                                  postType.postCommentModeration
                                  ? notice.postComment &&
                                  notice.postComment?.moderatedStatus
                                  : notice.postType ===
                                    postType.academicPostCommentModeration
                                    ? notice.academicRepoComment &&
                                    notice.academicRepoComment?.moderatedStatus
                                    : notice.postType ===
                                      postType.profilePictureNotice
                                      ? notice.profilePictureForModeration
                                        .moderatedStatus
                                      : notice.postType ===
                                        postType.coverPictureNotice
                                        ? notice.coverPicturesForModeration &&
                                        notice.coverPicturesForModeration[0]
                                          .moderatedStatus
                                        : notice.post && notice.post.moderatedStatus}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <p className="tt-noticeHeading line-clamp-1 ">
                        {notice.heading}{" "}
                        {notice.isUpdated ? "[ UPDATED ]" : null}
                      </p>
                      <div className="tt-showMobileGroup">
                        {notice.postType === postType.noticeCode ? (
                          <div className="tt-groupTargetList mt-1">
                            {notice.groups && notice.groups.length > 0 ? (
                              <div
                                onClick={(e) =>
                                  this.displayKeywordModal(e, notice.groups)
                                }
                              >
                                Groups Assigned
                              </div>
                            ) : (
                              <div>No Groups Assigned</div>
                            )}
                          </div>
                        ) : (
                          <div className="mt-4"></div>
                        )}
                      </div>
                      <div className="tt-mobileNoticeGroup">
                        {notice.postType === postType.noticeCode ? (
                          <div className="tt-groupTargetList mt-1">
                            {notice.groups && notice.groups.length > 0 ? (
                              notice.groups.map((group, idx) => {
                                if (group.name === "") {
                                  return null;
                                } else {
                                  return idx <= 1 ? (
                                    <div
                                      key={idx}
                                      data-tip={group.name}
                                      data-for="groupKey"
                                    >
                                      {group.name}
                                    </div>
                                  ) : idx === notice.groups.length - 1 ? (
                                    <div
                                      key={idx}
                                      style={{
                                        cursor: "pointer",
                                        width: "fit-content",
                                      }}
                                      onClick={(e) =>
                                        this.displayKeywordModal(
                                          e,
                                          notice.groups
                                        )
                                      }
                                    >
                                      + {notice.groups.length - 2} more
                                    </div>
                                  ) : (
                                    ""
                                  );
                                }
                              })
                            ) : (
                              <div>No Groups Assigned</div>
                            )}
                          </div>
                        ) : (
                          <div className="mt-1"></div>
                        )}
                      </div>
                      <div className="tt-noticeContent">
                        {ReactHtmlParser(notice.textContent)}
                      </div>
                      {notice.postType === postType.noticeCode ? (
                        ""
                      ) : (
                        <div style={{ marginTop: "2.5rem" }}></div>
                      )}

                      <div
                        className="row tt-group-header noticeFooter"
                        style={
                          notice.postType === postType.arrNoticeCode
                            ? { marginTop: "22px" }
                            : {}
                        }
                      >
                        <div className="col-md-3 tt-noticeFooterText">
                          <strong>Embedded link(s):</strong> {embeddedLinks}
                        </div>
                        <div className="col-md-3 tt-noticeFooterText">
                          <strong>Attached file(s):</strong>{" "}
                          {notice.contents.length - embeddedLinks}
                        </div>
                        <div className="col-md-6 text-right tt-noticeButton">
                          {notice.postType === postType.videoConference ? (
                            <button
                              className="tt-button tt-button-primary mr-3"
                              onClick={(e) =>
                                this.handleJoinConference(notice.id)
                              }
                            >
                              Join
                            </button>
                          ) : null}
                          {notice.postType === postType.assignment &&
                            notice.isTargettedStudent ? (
                            notice.assignmentStatus == "Pending" ? (
                              // this.checkSubmissionDate(
                              //   notice.assignmentDto?.submissionDate
                              // ) ?
                              notice.canSubmit ? (
                                <button
                                  className="tt-button tt-button-primary mr-3"
                                  onClick={(e) =>
                                    this.handleSubmitAssignment(notice)
                                  }
                                >
                                  Submit Assignment
                                </button>
                              ) : (
                                <button className="tt-button overdue-btn mr-3">
                                  Submission Overdue{" "}
                                </button>
                              )
                            ) : notice.assignmentStatus == "Correction" ? (
                              <button
                                className="tt-button tt-button-primary mr-3"
                                onClick={(e) =>
                                  this.handleSubmitAssignment(notice)
                                }
                              >
                                Re-submit Assignment
                              </button>
                            ) : (
                              <button
                                className="tt-button tt-button-primary mr-3"
                                onClick={(e) =>
                                  this.handleViewSubmittedAssignment(notice.id)
                                }
                              >
                                View Submitted Resources
                              </button>
                            )
                          ) : notice.postType === postType.assignment &&
                            !notice.isTargettedStudent ? (
                            <button
                              className="tt-button tt-button-primary mr-3"
                              onClick={(e) =>
                                this.handleAssignmentDropBoxClick(notice.id)
                              }
                            >
                              View Assignment DropBox
                            </button>
                          ) : null}
                          {notice.postType === postType.mcq ? (
                            notice.creatorPeopleId ===
                              parseInt(localStorage.getItem("peopleId")) ||
                              notice.isSubmitted === true ? (
                              <button
                                className="tt-button tt-button-primary mr-2"
                                onClick={(e) => this.openResult(e, notice)}
                              >
                                {notice.creatorPeopleId ===
                                  parseInt(localStorage.getItem("peopleId"))
                                  ? "Results"
                                  : "My Result"}
                              </button>
                            ) : (
                              <button
                                className="tt-button tt-button-primary mr-2"
                                onClick={(e) =>
                                  this.startTest(e, notice.mcqTestId)
                                }
                              >
                                Take a test
                              </button>
                            )
                          ) : null}
                          {notice.postType === postType.assignment ? (
                            <button
                              className="tt-button tt-button-primary"
                              onClick={(e) =>
                                this.toggleModal(
                                  e,
                                  notice,
                                  "open",
                                  "assignment"
                                )
                              }
                            >
                              Read more
                            </button>
                          ) : (
                            <button
                              className="tt-button tt-button-primary"
                              onClick={(e) =>
                                this.toggleModal(e, notice, "open", "")
                              }
                            >
                              Read more
                            </button>
                          )}
                        </div>
                      </div>
                      <ReactTooltip
                        className="tt-reactToolTip"
                        place="bottom"
                        id="dateReact"
                        globalEventOff="click"
                      />
                      <ReactTooltip
                        className="tt-reactTooltip"
                        place="bottom"
                        id="groupKey"
                        globalEventOff="click"
                      ></ReactTooltip>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="text-center mt-3">
                {this.state.noticeSpinner ? (
                  <>
                    <Spinner color="primary"></Spinner>
                    <br></br>Loading Notice...
                  </>
                ) : (
                  "No notices to display"
                )}
              </div>
            )
          ) : (
            <div className="text-center mt-3">
              {this.state.noticeSpinner ? (
                <>
                  <Spinner color="primary"></Spinner>
                  <br></br>Loading Notice...
                </>
              ) : (
                "No notices to display"
              )}
            </div>
          )}
          <ReactTooltip className="tt-reactToolTip" globalEventOff="click" />

          <ModalWindow
            modal={this.state.modal}
            toggleModal={this.toggleModal}
            modalClass="tt-noticeModal"
            fullWidth={true}
            modalHeader={
              this.state.notice.heading +
              (this.state.notice.isUpdated ? " [ UPDATED ]" : "")
            }
            modalBody={this.noticeModalBody()}
            modalFooter={this.noticeModalFooter()}
          ></ModalWindow>
          <ModalWindow
            modal={this.state.noticeModal}
            modalHeader="Notice Section"
            fullWidth={true}
            toggleModal={this.toggleNoticeModal}
            modalBody={
              <Posts
                toggleNoticeModal={this.toggleNoticeModal}
                noticeType={true}
                groupPage={this.props.groupPage ? this.props.groupPage : false}
                peopleId={this.props.peopleId}
              ></Posts>
            }
          //modalBody={"NOTICE HERE"}
          ></ModalWindow>
          <ModalWindow
            modal={this.state.keywordModal}
            toggleModal={this.toggleKeywordModal}
            modalHeader="Groups Targeted"
            modalBody={this.keywordModalBody()}
          ></ModalWindow>

          {this.state.roomId && this.state.userName && this.state.password && (
            <ModalWindow
              modal={this.state.jitsiModel}
              toggleModal={this.toggleJitsiModal}
              modalHeader="Jitsi Video Conferencing for Imagine Inquiry Sys"
              size="xl"
              modalBody={
                <JitsiMeet
                  isHost={this.state.isHost}
                  getMeetingId={this.state.roomId}
                  getDispNme={this.state.userName}
                  toggleModal={this.toggleJitsiModal}
                  getPassword={this.state.password}
                  videoConferenceId={this.state.videoConferenceId}
                ></JitsiMeet>
              }
            ></ModalWindow>
          )}

          {/* mcq */}
          <ModalWindow
            modal={this.state.mcqModal}
            // toggleModal={this.toggleMcqModal}
            toggleModal={
              this.state.mcqTest.testTypeId !== 1
                ? this.toggleMcqModal
                : this.cannotClose
            }
            size="xl"
            modalHeader="Online Exam"
            modalBody={
              <McqTestTemplate
                mcqTest={this.state.mcqTest}
                startTimerTest={this.startTimerTest}
                minute={this.state.minute}
                seconds={this.state.seconds}
                toggleMcqModal={this.toggleMcqModal}
                isSubmitted={this.state.isSubmitted}
                handleOptionClick={this.handleOptionClick}
                submitMcqTest={this.submitMcqTest}
              ></McqTestTemplate>
            }
          ></ModalWindow>
          <ModalWindow
            modal={this.state.mcqStudentListModal}
            toggleModal={this.toggleMcqStudentListModal}
            modalHeader="All Result"
            size="lg"
            modalBody={
              <McqStudentList
                mcqStudentList={this.state.mcqStudentList}
                viewStudentResult={this.viewStudentResult}
              ></McqStudentList>
            }
          ></ModalWindow>

          <ModalWindow
            modal={this.state.mcqResultModal}
            toggleModal={this.toggleMcqResultModal}
            size="xl"
            modalHeader="Result"
            modalBody={
              <McqResultTemplate
                mcqResult={this.state.mcqResult}
                correctAnswer={this.state.correctAnswer}
                unanswered={this.state.unanswered}
                incorrect={this.state.incorrect}
                totalAnswered={this.state.totalAnswered}
                fullMarks={this.state.fullMarks}
                obtainedMarks={this.state.obtainedMarks}
              ></McqResultTemplate>
            }
          ></ModalWindow>
          <ModalWindow
            modal={this.state.submissionModal}
            toggleModal={this.toggleSubmissionModal}
            fullWidth={true}
            modalHeader="Submit Assignment Resources"
            modalBody={
              <CourseClassResource
                isSubmission={true}
                assignment={true}
                toggleAddResourceModal={this.handleSubmitAssignment}
                saveResources={this.saveResources}
                assignmentContents={this.state.contents}
                assignmentLinks={this.state.links}
              ></CourseClassResource>
            }
          ></ModalWindow>

          <ModalWindow
            modal={this.state.submittedResourceModal}
            toggleModal={this.toggleSubmittedResourceModal}
            modalHeader="View Submitted Resources"
            fullWidth={true}
            modalBody={
              <>
                <CourseClassResources
                  showSpinner={this.state.showSpinner}
                  isSubmitted={true}
                  isSchoolAssignment={true}
                  selectedResources={this.state.submittedResources}
                  handleAssignmentReviewBatch={this.handleAssignmentReviewBatch}
                  remarks={this.state.remarks}
                  status={this.state.status}
                  handleChange={this.handleChange}
                  currentStudentStatus={this.state.currentStudentStatus}
                  mark={this.state.mark}
                  point={this.state.point}
                ></CourseClassResources>
              </>
              // <CourseClassResources
              //   isSchoolAssignment={true}
              //   selectedResources={this.state.submittedResources}
              //   isSubmitted={true}
              // ></CourseClassResources>
            }
          ></ModalWindow>
          <ModalWindow
            modal={this.state.assignmentDropboxModal}
            toggleModal={this.toggleAssignmentDropboxModal}
            modalHeader="Assignment Dropbox"
            // fullWidth={true}
            size="lg"
            modalBody={
              <SubmissionDetails
                assignmentStudent={this.state.assignmentStudent}
                handleSubmissionModal={this.handleSubmissionModal}
                handleViewSubmittedResources={this.handleViewSubmittedResources}
              />
            }
          ></ModalWindow>
        </div>
      </>
    ) : null;
  }
}

const mapStateToProps = (state, props) => ({
  notices: state.posts.notices,
  noticeSpinner: state.posts.noticeSpinner,
});
const mapActionToProps = {
  getNotices,
  getGroupNotices,
  getFamilyNotices,
  getNoticeSpinner,
};
export default connect(mapStateToProps, mapActionToProps)(NoticeDisplay);
