import React, { Component } from "react";
import FileViewer from "react-file-viewer";
import { URL as URLs, getValidHrefLink } from "../../utils/Constants";
import DocViewer, { PDFRenderer, DocViewerRenderers } from "react-doc-viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../common/CustomModal";
import ModalWindow from "../../UI/ModalWindow";
import axios from "axios";
import swal from "sweetalert";

export default class FileModal extends Component {
  state = {
    fileListStatus: "open",
    fileListStyle: "fileListMenu",
    fullModal: false,
    fileView: false,
    isPDF: false,
    initialLoad: false,
  };

  checkFilePath = (path) => {
    let data = URLs.imageSelectURL + path;
    var xhr = new XMLHttpRequest();
    // xhr.open("HEAD", data, false);
    // xhr.send();

    let status = parseInt(xhr.status);
    if (status == 404 || status == 401) {
      return false;
    } else {
      return true;
    }
  };

  handleClickShowList = () => {
    let element = document.getElementsByClassName("fileListMenu");
    if (element[0].classList.contains("activeFileList")) {
      element[0].classList.remove("activeFileList");
    } else {
      element[0].classList.add("activeFileList");
    }
  };

  downloadYoutubeVideo = (link) => {
    let updatedLink = link.replace("www.", "www.ss");
    return updatedLink;
  };

  handleBack = () => {
    this.setState({
      fullModal: false,
    });

    this.props.handleChangeFile(
      this.props.filePath,
      this.props.activeIndex,
      "files"
    );
  };

  fileViewUrl = async (path) => {
    if (!!path) {
      try {
        const response = await axios.get(URLs.imageSelectURL + path);
        this.setState({ fileView: true });
        return true;
      } catch (error) {
        if (error.response) {
          this.setState({ fileView: false });
          return false;
        }
      }
    }
  };

  componentDidMount() {
    // this.fileViewUrl(this.props.filePath);
    if (this.props.fileType === "pdf") {
      this.props.handleChangeFile(
        this.props.filePath,
        this.props.activeIndex,
        "files"
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filePath !== prevProps.filePath) {
      if (!!this.props.filePath && this.props.fileType === "pdf") {
        // console.log('Path has changed:', this.props.path);
        this.fileViewUrl(this.props.filePath);
      }
    }
  }

  render() {
    return (
      <div className="container-fluid fileModalDivide">
        <div className="">
          <div className="download-col mb-3">
            <h6
              className={
                this.props.fileType === "pdf"
                  ? "fileViewerHead"
                  : "fileViewerHead-md"
              }
            >
              {" "}
              <button
                className="ham-button mr-2"
                onClick={this.handleClickShowList}
              >
                <span
                  className="material-icons-round loginIcon"
                  aria-hidden="true"
                  style={{ fontSize: "38px" }}
                >
                  menu
                </span>
              </button>
              File Preview
              {this.props.fileType === "pdf" && this.state.fileView && (
                <span
                  onClick={() => {
                    // if (this.state.fileView) {
                    this.setState({
                      fullModal: true,
                    });
                    // } else {
                    //   swal("Error", "No file found to preview", "");
                    // }
                  }}
                  className="material-icons-round loginIcon"
                  aria-hidden="true"
                  style={{ fontSize: "30px" }}
                >
                  open_in_full
                </span>
              )}
              {this.props.fileType === "pdf" && !this.state.fileView && (
                <span className="" aria-hidden="true"></span>
              )}
            </h6>
          </div>
        </div>
        <div className="position-relative">
          <div className="resourceDownloadDiv">
            {this.props.fileExist &&
            this.props?.type !== "link" &&
            this.props.filePath !== "" ? (
              this.checkFilePath(this.props.filePath) ? (
                (this.state.fileType === "pdf" || this.state.isPDF) &&
                this.state.fileView ? (
                  <a
                    href={URLs.fileDownloadURL + this.props.filePath}
                    target="_blank"
                    download
                  >
                    <button className="">
                      <span
                        className="material-icons-round loginIcon"
                        aria-hidden="true"
                        style={{ fontSize: "30px" }}
                      >
                        download
                      </span>
                    </button>
                  </a>
                ) : null
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {this.props.fileExist &&
            this.props?.type !== "link" &&
            this.props.filePath !== "" ? (
              this.checkFilePath(this.props.filePath) ? (
                !(this.state.fileType === "pdf" || this.state.isPDF) ? (
                  <a
                    href={URLs.fileDownloadURL + this.props.filePath}
                    target="_blank"
                    download
                  >
                    <button className="">
                      <span
                        className="material-icons-round loginIcon"
                        aria-hidden="true"
                        style={{ fontSize: "30px" }}
                      >
                        download
                      </span>
                    </button>
                  </a>
                ) : null
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          <div className="file-preview-col">
            {this.state.initialLoad &&
            this.state.isPDF &&
            this.state.fileView ? (
              <FileViewer
                fileType={this.props.filePath.split(".").pop()}
                filePath={URLs.imageSelectURL + this.props.filePath}
              />
            ) : (
              <>
                {this.state.isPDF && this.state.fileView ? (
                  <div className="d-flex justify-content-center align-items-center">
                    {" "}
                    {/* No file available */}
                  </div>
                ) : null}
              </>
            )}
            {this.props.filePath !== "" && (
              <>
                {this.props.fileType === "TXT" ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.filePath }}
                  />
                ) : (this.props.fileType === "link" ||
                    this.props.fileType === "LNK") &&
                  this.props.filePath?.includes("youtube") ? (
                  <>
                    <iframe
                      width="100%"
                      src={this.props.filePath}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                      style={{ height: "70vh" }}
                    ></iframe>

                    <div className="resourceDownloadDiv">
                      <a
                        href={this.downloadYoutubeVideo(this.props.filePath)}
                        target="_blank"
                        download
                      >
                        <button className="">
                          <span
                            className="material-icons-round loginIcon"
                            aria-hidden="true"
                            style={{ fontSize: "30px" }}
                          >
                            download
                          </span>
                        </button>
                      </a>
                    </div>
                  </>
                ) : this.props.fileExist && this.props?.type !== "link" ? (
                  this.checkFilePath(this.props.filePath) ? (
                    (this.props?.fileType === "IMG" ||
                      this.props?.type === "image") &&
                    !this.state.isPDF ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "460px",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                          }}
                          src={`${URLs.imageSelectURL + this.props.filePath}`}
                          alt="Resource File"
                        />
                      </div>
                    ) : this.props.type === "media" ||
                      this.props.type === "medias" ? (
                      this.props.filePath !== "" && (
                        <FileViewer
                          fileType={this.props.filePath.split(".").pop()}
                          filePath={URLs.imageSelectURL + this.props.filePath}
                        />
                      )
                    ) : (
                      <>
                        {!this.state.initialLoad && this.state.fileView ? (
                          <FileViewer
                            fileType={this.props.filePath.split(".").pop()}
                            filePath={URLs.imageSelectURL + this.props.filePath}
                          />
                        ) : (
                          <>No file available</>
                        )}
                      </>
                    )
                  ) : (
                    <h5 className="text-center">"File Not Available"</h5>
                  )
                ) : (
                  <h5 className="text-center">"File Not Available"</h5>
                )}
              </>
            )}
          </div>
          {this.props.isNotice || this.props.isResourceFile ? (
            <div className={this.state.fileListStyle}>
              {this.props.files.length > 0 ? (
                <>
                  <h2>Files</h2>
                  <ul>
                    {this.props.files.map((datum, idx) => {
                      return (
                        <li style={{ wordWrap: "break-word" }}>
                          <a
                            onClick={
                              this.props.isNotice
                                ? () => {
                                    this.setState({ isPDF: false });
                                    this.props.handleChangeFile(
                                      datum,
                                      datum.content,
                                      datum.contentTypeCode,
                                      idx,
                                      "files"
                                    );
                                  }
                                : () => {
                                    this.setState({ isPDF: false });
                                    this.props.handleChangeFile(
                                      datum,
                                      datum.filePath,
                                      datum.fileType,
                                      idx,
                                      "files"
                                    );
                                  }
                            }
                          >
                            {this.props.activeIndex === datum.id &&
                            this.props.fileType === "PDF" ? (
                              <span className="activeIndex">
                                <u>
                                  {this.props.isNotice
                                    ? datum.origin
                                    : datum.fileName}
                                </u>
                              </span>
                            ) : this.props.isNotice ? (
                              datum.origin
                            ) : (
                              datum.fileName
                            )}
                          </a>
                          {(this.props.lmsFile || this.props.syllabusFile) &&
                            this.props.canDeleteResources && (
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="resourceDeleteIcon"
                                onClick={() => this.props.deleteResource(datum)}
                              />
                            )}
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                ""
              )}
              {this.props.images.length > 0 ? (
                <>
                  <h2>Images</h2>
                  <ul>
                    {this.props.images.map((datum, idx) => {
                      return (
                        <li style={{ wordWrap: "break-word" }}>
                          <a
                            onClick={
                              this.props.isNotice
                                ? () => {
                                    this.setState({ isPDF: false });
                                    this.props.handleChangeFile(
                                      datum,
                                      datum.content,
                                      datum.contentTypeCode,
                                      idx,
                                      "image"
                                    );
                                  }
                                : () => {
                                    this.setState({ isPDF: false });
                                    this.props.handleChangeFile(
                                      datum,
                                      datum.filePath,
                                      datum.fileType,
                                      idx,
                                      "image"
                                    );
                                  }
                            }
                          >
                            {this.props.activeIndex === datum.id &&
                            this.props.fileType === "IMG" ? (
                              <span className="activeIndex">
                                <u>
                                  {this.props.isNotice
                                    ? datum.origin
                                    : datum.fileName}
                                </u>
                              </span>
                            ) : this.props.isNotice ? (
                              datum.origin
                            ) : (
                              datum.fileName
                            )}
                          </a>
                          {(this.props.lmsFile || this.props.syllabusFile) &&
                            this.props.canDeleteResources && (
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="resourceDeleteIcon"
                                onClick={() => this.props.deleteResource(datum)}
                              />
                            )}
                        </li>
                      );
                    })}
                  </ul>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className={this.state.fileListStyle}>
              {this.props.images?.length > 0 ? (
                <div>
                  <ul>
                    <li>Images</li>
                    <ol type="i">
                      {this.props.images?.map((datum, idx) => {
                        return (
                          <li style={{ wordWrap: "break-word" }}>
                            <a
                              // onClick={async () => {
                              //   let errState = await this.fileViewUrl(
                              //     datum.filePath
                              //   );
                              //   if (errState) {
                              //     this.props.handleChangeFile(
                              //       datum.filePath,
                              //       idx,
                              //       "image"
                              //     );
                              //   }
                              // }}
                              onClick={() => {
                                this.setState({ isPDF: false });
                                this.props.handleChangeFile(
                                  datum.filePath,
                                  idx,
                                  "image"
                                );
                              }}
                            >
                              {this.props.activeIndex === idx &&
                              this.props.type === "image" ? (
                                <span className="activeIndex">
                                  <u>{datum.fileName}</u>
                                </span>
                              ) : (
                                datum.fileName
                              )}
                            </a>
                            {(this.props.lmsFile || this.props.syllabusFile) &&
                              this.props.canDeleteResources && (
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="resourceDeleteIcon"
                                  onClick={() =>
                                    this.props.deleteResource(datum)
                                  }
                                />
                              )}
                          </li>
                        );
                      })}
                    </ol>
                  </ul>
                </div>
              ) : (
                ""
              )}

              {this.props.medias?.length > 0 ? (
                <div>
                  <ul>
                    {this.props.medias?.filter(
                      (el) => el.fileType === "AUD" || el.fileType === "VID"
                    ).length > 0 ? (
                      <li>Media Files</li>
                    ) : null}

                    <ol type="i">
                      {this.props.medias?.map((filterDatum, idx) => {
                        return (
                          <li style={{ wordWrap: "break-word" }}>
                            <a
                              onClick={() => {
                                this.setState({ isPDF: false });
                                this.props.handleChangeFile(
                                  filterDatum.filePath,
                                  idx,
                                  "medias"
                                );
                              }}
                            >
                              {(this.props.activeIndex === idx &&
                                this.props.type === "medias") ||
                              this.props.type === "media" ? (
                                <span className="activeIndex">
                                  <u>{filterDatum.fileName}</u>
                                </span>
                              ) : (
                                filterDatum.fileName
                              )}
                            </a>
                            {(this.props.lmsFile || this.props.syllabusFile) &&
                              this.props.canDeleteResources && (
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="resourceDeleteIcon"
                                  onClick={() =>
                                    this.props.deleteResource(filterDatum)
                                  }
                                />
                              )}
                          </li>
                        );
                      })}
                    </ol>
                  </ul>
                </div>
              ) : null}

              {this.props.files.length > 0 ? (
                <div>
                  <ul>
                    {this.props.files.filter((el) => el.fileType === "PDF")
                      .length > 0 ? (
                      <li>Attached Files</li>
                    ) : null}

                    <ol type="i">
                      {this.props.files
                        // .filter((datum) => datum.fileType == "PDF")
                        .map((filterDatum, idx) => {
                          return (
                            <li style={{ wordWrap: "break-word" }}>
                              <a
                                onClick={async () => {
                                  let errState = await this.fileViewUrl(
                                    filterDatum.filePath
                                  );
                                  this.setState({
                                    isPDF: true,
                                    initialLoad: false,
                                  });
                                  if (errState) {
                                    this.props.handleChangeFile(
                                      filterDatum.filePath,
                                      idx,
                                      "files"
                                    );
                                  } else {
                                    this.handleClickShowList();
                                  }
                                }}
                              >
                                {this.props.activeIndex === idx &&
                                this.props.type === "files" ? (
                                  <span className="activeIndex">
                                    <u>{filterDatum.fileName}</u>
                                  </span>
                                ) : (
                                  filterDatum.fileName
                                )}
                              </a>
                              {(this.props.lmsFile ||
                                this.props.syllabusFile) &&
                                this.props.canDeleteResources && (
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="resourceDeleteIcon"
                                    onClick={() =>
                                      this.props.deleteResource(filterDatum)
                                    }
                                  />
                                )}
                            </li>
                          );
                        })}
                    </ol>
                  </ul>
                </div>
              ) : null}

              {this.props.links.length > 0 ? (
                <div>
                  <ul>
                    <li>Links</li>
                    <ol type="i">
                      {this.props.links.map((datum, idx) => {
                        return (
                          <li style={{ wordWrap: "break-word" }}>
                            {datum.filePath?.includes("youtube") ||
                            datum.filePath?.includes("youtu.be") ||
                            datum.link?.includes("youtube") ||
                            datum.link?.includes("youtu.be") ? (
                              <a
                                onClick={() => {
                                  this.setState({ isPDF: false });
                                  this.props.handleChangeFile(
                                    datum,
                                    idx,
                                    "link"
                                  );
                                }}
                              >
                                {this.props.activeIndex === idx &&
                                this.props.type === "link" ? (
                                  <span className="activeIndex">
                                    <u>
                                      {datum.fileName}
                                      {/* {" "}
                                      {datum.link == null
                                        ? datum.fileName
                                        : datum.links} */}
                                    </u>
                                  </span>
                                ) : datum.fileName ? (
                                  datum.fileName
                                ) : (
                                  datum.links || datum.link
                                )}
                              </a>
                            ) : (
                              <a
                                href={
                                  this.props.lmsFile
                                    ? getValidHrefLink(datum.filePath) === null
                                      ? "#"
                                      : getValidHrefLink(datum.filePath)
                                    : getValidHrefLink(datum.link) === null
                                    ? "#"
                                    : getValidHrefLink(datum.link)
                                }
                                // href={
                                //   this.props.lmsFile
                                //     ? getValidHrefLink(datum.filePath) === null
                                //       ? "#"
                                //       : getValidHrefLink(datum.filePath)
                                //     : getValidHrefLink(datum.filePath) === null
                                //     ? "#"
                                //     : getValidHrefLink(datum.filePath)
                                //   // datum.link.includes("http")?datum.link:"http://"+datum.link
                                // }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {this.props.activeIndex === idx &&
                                this.props.type === "link" ? (
                                  <span className="activeIndex">
                                    <u>
                                      {" "}
                                      {datum.link == null
                                        ? datum.fileName
                                        : datum.links || datum.link}
                                    </u>
                                  </span>
                                ) : datum.link == null ? (
                                  datum.fileName
                                ) : (
                                  datum.links || datum.link
                                )}
                              </a>
                            )}
                            {(this.props.lmsFile || this.props.syllabusFile) &&
                              this.props.canDeleteResources && (
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="resourceDeleteIcon"
                                  onClick={() =>
                                    this.props.deleteResource(datum)
                                  }
                                />
                              )}
                          </li>
                        );
                      })}
                    </ol>
                  </ul>
                </div>
              ) : (
                ""
              )}

              {this.props.contents?.length > 0 ? (
                <div>
                  <ul>
                    <li>Contents</li>
                    <ol type="i">
                      {this.props.contents?.map((datum, idx) => {
                        return (
                          <li style={{ wordWrap: "break-word" }}>
                            <a
                              onClick={() =>
                                this.props.handleChangeFile(datum, idx, "txt")
                              }
                            >
                              {this.props.activeIndex === idx &&
                              this.props.type === "txt" ? (
                                <span className="activeIndex">
                                  <u>{datum.filePath}</u>
                                </span>
                              ) : (
                                datum.filePath
                              )}
                            </a>
                          </li>
                        );
                      })}
                    </ol>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>

        {this.state.fullModal && (
          <div className="fullWindowPDF">
            <span
              className="material-icons-round closeFullPDF"
              aria-hidden="true"
              style={{ fontSize: "30px" }}
              onClick={this.handleBack}
            >
              arrow_back
            </span>

            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={[{ uri: URLs.imageSelectURL + this.props.filePath }]}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: false,
                },
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
