import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DashboardPage from "../Dashboard/DashboardPage";
import CalendarEvents from "../../pages/Calendar/CalendarEvents";
import MyCalendar from "../../pages/Calendar/MyCalendar";
import MyProfile from "../../pages/Profile/MyProfile";
import Modules from "../../pages/RolesAndPermissions/Modules";
import RolesAndPermissions from "../../pages/RolesAndPermissions/RolesAndPermissions";
import SchoolLists from "../../pages/SchoolManagement/SchoolLists";
import UserList from "../../pages/UserManagement/UserList";
import NotFound from "../Error Page/NotFound";
import Slider from "../../pages/Slider/Slider";
import EducationLevel from "../../pages/EducationLevel/EducationLevel";
import Class from "../../pages/Class/Class";
import Menu from "../../pages/Menu/Menu";
import CurrentTraining from "../../pages/CurrentTraining/CurrentTraining";
import MainFeature from "../../pages/MainFeature/MainFeature";
import CoursePlan from "../../pages/CoursePlan/CoursePlan";
import Subject from "../../pages/Subject/Subject";
import SchoolStatistics from "../../pages/Reports/SchoolStatistics";
import UserLoginStatistics from "../../pages/Reports/UserLoginStatistics";
import TeacherTraining from "../../pages/TeacherTraining/TeacherTraining";
import SystemExamConfiguration from "../../pages/ExamModule/SystemExamConfiguration";
import SystemExamAssessment from "../../pages/ExamModule/SystemExamAssessment";
import ELibrary from "../ELibrary/ELibrary";
import CentralFeedbackList from "../Feedbacks/CentralFeedbackList";
import WebsiteConfig from "../../pages/WebsiteConfig/WebsiteConfig";
import OtherActivitiesComponent from "../OtherActivities/OtherActivitiesComponent";
// import CreateSubjectAssignment from "../../pages/Assignment/CreateSubjectAssignment";
import Notice from "../../pages/Notices/Notice";
import TeacherMaterial from "../TeacherMaterial/TeacherMaterial";
import Dictionary from "../Dictionary/Dictionary";
import CentralVideoConference from "../VideoConferencing/CentralVideoConference";
import CentralGroup from "../CentralGroup/CentralGroup";
import DiscussionDashboard from "../CentralDiscussion/DiscussionDashboard";
import CustomCalculator from "../Calculator/CustomCalculator";
// import CentralCurriculum from "../ReadingMaterials/CentralCurriculum";
// import CentralTextbooks from "../ReadingMaterials/CentralTextbooks";
// import CentralQuestionBanks from "../ReadingMaterials/CentralQuestionBanks";
// import CentralAudioVisual from "../ReadingMaterials/RM-old/CentralAudioVisual";
// import CentralSignLanguage from "../ReadingMaterials/CentralSignLanguage";
import CentralMCQ from "../GameBaseLearning/CentralMCQ";
import CentralFlashCards from "../GameBaseLearning/CentralFlashCards";
import ExamConfigList from "../../pages/ExamModule/ExamConfigList";
import CentralReadingMaterials from "../ReadingMaterials/CentralReadingMaterials";
import RelativeLinks from "../../pages/RelativeLinks/RelativeLinks";
import SearchPage from "../SearchModule/SearchPage";
import RelativeLinksPage from "../../pages/RelativeLinks/RelativeLinksPage";
import ResourcesSummary from "../../pages/Reports/ResourcesSummary";
import CentralAcademicRepo from "../../pages/Reports/CentralAcademicRepo";
import ARRManager from "../Permissions/ARRManager";

class DashboardRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Switch>
          <Route path="/dashboard/main" component={DashboardPage} />
          <Route path="/dashboard/view_calendar" component={MyCalendar} />
          <Route path="/dashboard/calendar_events" component={CalendarEvents} />
          <Route path="/dashboard/user" component={UserList} />
          <Route path="/dashboard/schools" component={SchoolLists} />
          <Route path="/dashboard/profile" component={MyProfile} />
          <Route path="/dashboard/modules" component={Modules} />
          <Route path="/dashboard/dictionary" component={Dictionary} />
          <Route
            path="/dashboard/customCalculator"
            component={CustomCalculator}
          />

          <Route
            path="/dashboard/centralCurriculum"
            render={() => (
              <CentralReadingMaterials lms="curriculum" title="Curriculum" />
            )}
          />
          <Route
            path="/dashboard/centralTextbooks"
            render={() => (
              <CentralReadingMaterials lms="textbooks" title="Textbooks" />
            )}
          />
          <Route
            path="/dashboard/centralQuestionBanks"
            render={() => (
              <CentralReadingMaterials
                lms="question-bank"
                title="Question Banks"
              />
            )}
          />
          <Route
            path="/dashboard/centralAudioVisual"
            render={() => (
              <CentralReadingMaterials
                lms="av-resource"
                title="Audio Visuals"
              />
            )}
          />
          <Route
            path="/dashboard/centralSignLanguage"
            render={() => (
              <CentralReadingMaterials
                lms="sign-language"
                title="Sign Languages"
              />
            )}
          />
          <Route path="/dashboard/centralMCQ" component={CentralMCQ} />
          <Route
            path="/dashboard/centralFlashCards"
            component={CentralFlashCards}
          />
          <Route
            path="/dashboard/roles_permission"
            component={RolesAndPermissions}
          />
          <Route
            path="/dashboard/otherActivityCategory"
            component={OtherActivitiesComponent}
          />
          <Route
            path="/dashboard/relative-links"
            component={RelativeLinksPage}
          />
          <Route path="/dashboard/relativeLinks" component={RelativeLinks} />
          <Route
            path="/dashboard/student-material"
            render={() => <ELibrary schoolProfile={false} />}
          />
          <Route
            path="/dashboard/teacher-material"
            component={TeacherMaterial}
          />
          <Route path="/dashboard/slider" component={Slider} />
          <Route path="/dashboard/educationLevel" component={EducationLevel} />
          <Route path="/dashboard/class" component={Class} />
          <Route path="/dashboard/subject" component={Subject} />
          <Route path="/dashboard/arrmanager" component={ARRManager} />
          <Route path="/dashboard/menu" component={Menu} />
          <Route
            path="/dashboard/currentTraining"
            component={CurrentTraining}
          />
          <Route path="/dashboard/mainFeature" component={MainFeature} />
          <Route path="/dashboard/courseplan" component={CoursePlan} />
          {/* <Route path="/courseplanTeacher" component={<CoursePlanTeacher />} /> */}
          <Route
            path="/dashboard/schoolStatistics"
            component={SchoolStatistics}
          />
          <Route
            path="/dashboard/teacherTraining"
            component={TeacherTraining}
          />
          <Route
            path="/dashboard/examconfig"
            component={SystemExamConfiguration}
          />
          <Route path="/dashboard/examconfigList" component={ExamConfigList} />
          {/* <Route path="/dashboard/examlist" component={ExamForm} /> */}
          <Route path="/dashboard/checkExam" component={SystemExamAssessment} />
          <Route path="/dashboard/feedbacks" component={CentralFeedbackList} />
          <Route path="/dashboard/notices" component={Notice} />
          <Route path="/dashboard/website-config" component={WebsiteConfig} />
          {/* <Route path="/dashboard/footer-config" component={FooterDashboard} /> */}
          <Route
            path="/dashboard/video-conference"
            component={CentralVideoConference}
          />
          <Route path="/dashboard/group" component={CentralGroup} />
          <Route path="/dashboard/discussion" component={DiscussionDashboard} />

          {/* <Route
            path="/createAssignment"
            component={<CreateSubjectAssignment />}
          /> */}
          <Route
            path="/dashboard/userLoginStatistics"
            component={UserLoginStatistics}
          />

          <Route
            path="/dashboard/resourcesSummary"
            component={ResourcesSummary}
          />
          <Route
            path="/dashboard/academicCentralRepo"
            component={CentralAcademicRepo}
          />
          <Route path="/dashboard/search-imagine" component={SearchPage} />
          <Route path="*" component={NotFound} />
          <Route path="/dashboard/learningHub" component={"LEARNING HUB"} />
          <Route path="/dashboard/games" component={"GAMES"} />
        </Switch>
      </div>
    );
  }
}

export default DashboardRoutes;
